<template>
    <div class="containerColumnLeft">

    <div class="tableContainer">
        <div class="tableHeaderRow">
            <div class="grid-cell titleGrayBackground"> {{ $t('top_item_page.rank') }} </div>
            <div class="grid-cell titleGrayBackground">HFB</div>
            <div class="grid-cell titleGrayBackground"> {{ $t('top_item_page.item_nr') }} </div>
            <div class="grid-cell titleGrayBackground"> {{ $t('top_item_page.item_name') }} </div>
            <div class="grid-cell storeTitle"> {{ $t('sales_channels.store') }} </div>
            <div class="grid-cell onlineTitle"> {{ $t('sales_channels.online_csc') }} </div>
            <div class="grid-cell totalSalesTitle"> {{ $t('chart_keys.total_sales') }} </div>
        </div>

        <div class="tableDataRow" v-for="topItem in topItemsByValue" :key="topItem.rank">
            <div class="grid-cell firstColumnGrayBackground">{{ topItem.rank }}</div>
            <div class="grid-cell columnLeftPlacement"> {{ translateHFB(topItem.hfb_cap) }} </div>
            <div class="grid-cell columnLeftPlacement"> {{ topItem.item_no }} </div>
            <div class="grid-cell columnLeftPlacement"> {{ topItem.item_name }} </div>
            <div class="grid-cell columnRightPlacement"> {{ topItem.total_sale_gross_store }} </div>
            <div class="grid-cell columnRightPlacement"> {{ topItem.total_sale_gross_online }} </div>
            <div class="grid-cell columnRightPlacement"> {{ topItem.total_sale_gross }} </div>
        </div>
    </div>
    </div>

</template>

<script>

var formatNumberGenerator = require('../FormatNumberGenerator');

export default {
    name: 'BySalesValueTable',

    data() {
        return {
        }
    },
    methods: {
        formatNumber(input)
        {
            let formatter = formatNumberGenerator({
                decimal_place: this.$t('html_formatting.decimal_place'),
                thousands_seperator:  this.$t('html_formatting.thousands_seperator')
            })

            return formatter(input)        
        },
        translateHFB(input){
            
            let translation = input.slice(0, 2) + ' ' + this.$t('hfb_names.' + input.slice(0, 2))

            return translation;
        },
    },
    computed: {
        topItemsByValue() {
          return this.$store.state.page4data.topItemsByValue;
      }, 
    },
    mounted() {
    },
    watch: {
    }
}
</script>

<style scoped>

    .containerColumnLeft{
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 92% 8%;
    }

    .tableContainer{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: repeat(11, 1fr);
    }

    .tableHeaderRow{
        display: grid;
        grid-template-columns: 4% 19% 12% 32% 10% 11% 12%;
        grid-template-rows: auto;
        font-size: 0.55rem;
        padding: 0;
        text-align: left;
        font-weight: bold;
    }

    .tableDataRow{
        display: grid;
        grid-template-columns: 4% 19% 12% 32% 10% 11% 12%;
        grid-template-rows: auto;
        font-size: 0.50rem;
        padding: 0;
        text-align: left;
    }

    .grid-cell{
        border: 1px solid black;
    }

    .titleGrayBackground {
        text-align: left;
        padding: 6px 0px 0px 4px;
        background: rgb(214, 214, 214);
    }

    .firstColumnGrayBackground {
        text-align: right;
        font-weight: bold;
        padding: 7px 6px 0px 0px;
        background: rgb(214, 214, 214);
    }

    .columnLeftPlacement {
        text-align: left;
        font-weight: bold;
        padding: 6px 0px 0px 4px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis; 
    }

    .columnRightPlacement {
        text-align: right;
        font-weight: bold;
        padding: 6px 6px 0px 0px;
    }

    .storeTitle {
        text-align: right;
        padding: 6px 6px 0px 0px;
        background: rgb(58, 140, 255);
    }

    .onlineTitle {
        text-align: right;
        padding: 6px 6px 0px 0px;
        background: rgb(255, 138, 35);
    }

    .totalSalesTitle {
        text-align: right;
        padding: 6px 6px 0px 0px;
        background: rgb(36, 176, 9);
    }

    .filterColumnAlignment {
        text-align: right;
    }

</style>