<template>
    <div class="storewide-details">
        <template v-if="propLatestTransaction !== '' || latestTransaction !== ''">
            <div class="clock-symbol">
                <img src="/clock.png" />
            </div>
            <span class="transaction-label">{{ $t('data_info.latest_transaction') }}:<br></span>
            <div class="transaction-time">{{ propLatestTransaction || latestTransaction }}</div>
        </template>
    </div>
</template>

<script>

export default {
    name: 'LatestTransaction',
    props: {
      propLatestTransaction: String
    },
    computed: {
        latestTransaction() {
            return this.$store.state.salesChannelsData.lastTransaction;
        },
    },
    data: function() {
        return {
        }
    },
    mounted() {
    },
}
</script>

<style scoped>
.clock-symbol {
  float: left;
  margin-right: 0.3em;
  height: 3em;
}
.clock-symbol img {
  height: 3em;
}
.transaction-label {
  font-size: 0.7em;
  color: #b3b3b3;
  text-transform: uppercase;
}
.transaction-time {
  font-size: 0.7rem;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
