module.exports = function(formatters) {
    
    return function(input) {        
        let minus = (input < 0);
        let number = Math.abs(input);
        let numberAmount;
        let result;

        if (number >= 1000000)
        {
            let divided = number / 100000;
            let rounded = Math.round(divided);
            numberAmount = rounded / 10;
            result = numberAmount.toString() + ' M';
        }
        else if (number >= 1000)
        {
            let divided = number / 100;
            let rounded = Math.round(divided);
            numberAmount = rounded / 10;
            result = numberAmount.toString() + ' K';
        }
        else
        {
            let multipliedByTen = number * 10;
            let rounded = Math.round(multipliedByTen);
            numberAmount = rounded / 10;
            result = numberAmount.toString();
        }

        if (minus && (numberAmount != 0))
        {
            result = '- ' + result;
        }

        result = result.replace(',', '!');
        result = result.replace('.', formatters.decimal_place);
        result = result.replace('!', formatters.thousands_seperator);
        

        return result;
    }
}