<template>
  <div class="hello">
    <div id="storeAndOnlineChart" style="height: 180px; width: 800px;"></div>
    <div v-if="selectedChannels === 'all'" id="bothChart" style="height: 180px; width: 800px;"></div>
  </div>
  
</template>

<script>

function convertThousandsSeparator (input){
    return input.replaceAll(',', htmlFormatting.thousands_seperator)
}

function goalBarTitleCallback(object)
{
    return convertThousandsSeparator(object.data.Goal.toLocaleString());
}

function salesBarTitleCallback(object)
{
    return convertThousandsSeparator(object.data.Sales.toLocaleString());
}

var echarts = require('echarts');

var formatNumberGenerator = require('../FormatNumberGenerator');
var salesAndGoalLegendFormatter = require('../SalesAndGoalLegendFormatter');
var salesAndGoalLegendTranslation = require('../SalesAndGoalLegendTranslation');

var eChartStoreAndOnline;
var eChartTotal;

let htmlFormatting = {
    decimal_place:null, 
    thousands_seperator:null
};

var storeAndOnlineChartOptions;
var totalChartOptions;

function formatNumber(input)
{
    let formatter = formatNumberGenerator({
        decimal_place:htmlFormatting.decimal_place,
        thousands_seperator:htmlFormatting.thousands_seperator
    })

    return formatter(input)
}

var commonChartOptionsSeriesGoal = {
    type: 'bar',
    barGap: '0%',
    itemStyle: {
        color: 'rgba(217, 179, 0, 1)',
    },
    label: {
        show: true,
        formatter: goalBarTitleCallback,
        color: 'rgba(0, 0, 0, 1)',
        position: 'right',
        distance: 7,
        fontWeight: '',
    },
};

var commonChartOptions = {
    legend: {
        left: '10%',
    },
    title: {},
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        }
    },
    textStyle: {
        fontSize: '',
    },
    yAxis: {
        type: 'category',
        axisLabel: {
            fontSize: ''
        },
    },
    xAxis: {
        type: 'value',
        axisLabel: {
            formatter: function (value, index)
            {
                return formatNumber(value)
            },
            axisLabel: {
                fontSize: ''
            },
        },
    },
    series: [
        {
            type: 'bar',
            itemStyle: {
                color: 'rgba(30, 161, 90, 1)',
            },
            label: {
                show: true,
                formatter: salesBarTitleCallback,
                color: 'rgba(0, 0, 0, 1)',
                position: 'right',
                distance: 7,
                fontWeight: '',
            },
        },
    ]
}
var storeAndOnlineChartOnly = {
    grid: {
        left: '1%',
        right: '9%',
        bottom: '3%',
        containLabel: true
    },
    dataset: {
        dimensions: ['channel', 'Sales', 'Goal'],
        source: []
    },
}

var totalChartOnly = {
    legend: {
        show: false,
    },
    grid: {
        left: '2%',
        right: '9%',
        top: '5%',
        bottom: '17%',
        containLabel: true
    },
    dataset: {
        dimensions: ['channel', 'Sales', 'Goal'],
        source: []
    },
}

export default {
    name: 'SalesChannelsGraph',
    props: {
        msg: String
    },
    computed: {
        salesChannelsData() {
            return this.$store.state.salesChannelsData;
        },
        appContainerWidth() {
            return this.$store.state.appContainerWidth;
        },
        goalValuesEnabledCurrentPage()
        {
            return this.$store.state.goalValuesEnabledCurrentPage;
        },
        selectedChannels() {
            if (this.$route.path === '/') return 'all';
            const currentPage = this.$store.state.currentPageNumber;
            const all = this.$store.state.kioskConfiguration[`page${currentPage}_all_channels`];
            const channels = this.$store.state.kioskConfiguration[`page${currentPage}_selected_channels`];
            if (all) return 'all';
            return channels;
        },
    },
    data() {
        let formatNumber = this.formatNumber
        const translations = {sales: this.$t('chart_keys.sales'), goal: this.$t('chart_keys.goal')};
        return {
            salesAndGoalLegendFormatter: salesAndGoalLegendTranslation(translations),
            storeAndOnlineChartOptions: null,
            totalChartOptions: null,
        };
    },
    mounted() {
        htmlFormatting.decimal_place = this.$t('html_formatting.decimal_place'),
        htmlFormatting.thousands_seperator =  this.$t('html_formatting.thousands_seperator')

        if (this.goalValuesEnabledCurrentPage)
        {
            commonChartOptions.series[1] = commonChartOptionsSeriesGoal;
        }

        this.storeAndOnlineChartOptions = {...commonChartOptions, ...storeAndOnlineChartOnly};
        this.totalChartOptions = {...commonChartOptions, ...totalChartOnly};

        this.storeAndOnlineChartOptions.legend.formatter = this.salesAndGoalLegendFormatter;
        this.totalChartOptions.legend.formatter = this.salesAndGoalLegendFormatter;

        eChartStoreAndOnline = echarts.init(document.getElementById('storeAndOnlineChart'));
        if (this.selectedChannels === 'all') eChartTotal = echarts.init(document.getElementById('bothChart'));
        
        if (this.salesChannelsData.lastTransaction !== '')
        {
            this.storeAndOnlineChartOptions.dataset.source[0] = this.salesChannelsData.storeAndOnlineGraphData[0];
            this.storeAndOnlineChartOptions.dataset.source[1] = this.salesChannelsData.storeAndOnlineGraphData[1];
            this.totalChartOptions.dataset.source[0] = this.salesChannelsData.totalGraphData[0];
            
            this.storeAndOnlineChartOptions.dataset.source[0].channel = this.$t('sales_channels.online_csc_lbr');
            this.storeAndOnlineChartOptions.dataset.source[1].channel = this.$t('sales_channels.store');
            this.totalChartOptions.dataset.source[0].channel = this.$t('sales_channels.total');

            this.enableOrDisbaleGoalVaules();

            this.filterChannels(this.storeAndOnlineChartOptions)

            eChartStoreAndOnline.setOption(this.storeAndOnlineChartOptions);
            if (this.selectedChannels === 'all') eChartTotal.setOption(this.totalChartOptions);
        }
    },
    methods: {
        setChartFontSize(newAppWidth) {
            this.storeAndOnlineChartOptions.textStyle.fontSize = Math.round(newAppWidth / 170);
            this.storeAndOnlineChartOptions.xAxis.axisLabel.fontSize = Math.round(newAppWidth / 155);
            this.storeAndOnlineChartOptions.yAxis.axisLabel.fontSize = Math.round(newAppWidth / 155);

            this.totalChartOptions.textStyle.fontSize = Math.round(newAppWidth / 170);
            this.totalChartOptions.xAxis.axisLabel.fontSize = Math.round(newAppWidth / 155);
            this.totalChartOptions.yAxis.axisLabel.fontSize = Math.round(newAppWidth / 155);

            eChartStoreAndOnline.setOption(this.storeAndOnlineChartOptions, true);
            if (this.selectedChannels === 'all') eChartTotal.setOption(this.totalChartOptions, true);
        },
        enableOrDisbaleGoalVaules() {
            if (this.goalValuesEnabledCurrentPage)
            {
                this.storeAndOnlineChartOptions.dataset.dimensions = ['channel', 'Sales', 'Goal'];
                this.totalChartOptions.dataset.dimensions = ['channel', 'Sales', 'Goal'];
                this.storeAndOnlineChartOptions.series[1] = commonChartOptionsSeriesGoal;
                this.totalChartOptions.series[1] = commonChartOptionsSeriesGoal;
            }
            else
            {
                this.storeAndOnlineChartOptions.dataset.dimensions = ['channel', 'Sales'];
                this.totalChartOptions.dataset.dimensions = ['channel', 'Sales'];
                this.storeAndOnlineChartOptions.series[1] = null;
                this.totalChartOptions.series[1] = null;
            }
        },
        filterChannels(chartOptions) {
            let configuredChannels = this.selectedChannels;
            if (configuredChannels === 'all') return;
            if (configuredChannels !== 'all') configuredChannels = configuredChannels.split(',').map(channel => channel.toLowerCase());
            chartOptions.dataset.source = chartOptions.dataset.source.filter(channel => {
                return configuredChannels === 'all' ? true : configuredChannels.includes(channel.channel.toLowerCase())
            }) 
        },
        resizeGraphs(newAppWidth) {
            const chartWidthFactor_both = 0.43;
            
            const container_storeAndOnline = document.getElementById('storeAndOnlineChart');
            const chartHeightFactor_storeAndOnline = 0.12;
            const newChartHeight_storeAndOnline = newAppWidth * chartHeightFactor_storeAndOnline;

            const container_total = document.getElementById('bothChart');
            const chartHeightFactor_total = 0.07;
            const newChartHeight_total = newAppWidth * chartHeightFactor_total;


            const newChartWidth_both = newAppWidth * chartWidthFactor_both;

            container_storeAndOnline.style.width = (newChartWidth_both)+"px";
            container_storeAndOnline.style.height = (newChartHeight_storeAndOnline)+"px";

            if (this.selectedChannels === 'all') {
                container_total.style.width = (newChartWidth_both)+"px";
                container_total.style.height = (newChartHeight_total)+"px";
            }


            eChartStoreAndOnline.resize({width: newChartWidth_both, height: newChartHeight_storeAndOnline});
            if (this.selectedChannels === 'all') eChartTotal.resize({width: newChartWidth_both, height: newChartHeight_total});
            this.setChartFontSize(newAppWidth);
        }
    },
    watch: {
        salesChannelsData: {
            handler(newValue) {
                if (this.selectedChannels === 'all') eChartTotal = echarts.init(document.getElementById('bothChart'));
                this.resizeGraphs(this.appContainerWidth)

                this.storeAndOnlineChartOptions.dataset.source = newValue.storeAndOnlineGraphData;
                this.totalChartOptions.dataset.source = newValue.totalGraphData;

                this.storeAndOnlineChartOptions.dataset.source[0].channel = this.$t('sales_channels.online_csc_lbr');
                this.storeAndOnlineChartOptions.dataset.source[1].channel = this.$t('sales_channels.store');
                this.totalChartOptions.dataset.source[0].channel = this.$t('sales_channels.total');

                this.filterChannels(this.storeAndOnlineChartOptions);


                eChartStoreAndOnline.setOption(this.storeAndOnlineChartOptions, true);
                eChartTotal.setOption(this.totalChartOptions, true);
            },
            deep: true,
        },
        goalValuesEnabledCurrentPage()
        {
            this.enableOrDisbaleGoalVaules();

            eChartStoreAndOnline.setOption(this.storeAndOnlineChartOptions, true);
            eChartTotal.setOption(this.totalChartOptions, true);
        },
        appContainerWidth(newAppWidth) {
            this.resizeGraphs(newAppWidth)
        },
    },
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
