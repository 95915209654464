<template>
<div>
  <WarningBlock />
  <router-view/>
</div>
</template>

<script>

import WarningBlock from './components/WarningBlock.vue';

export default {
  name: 'app',
  components: {
    WarningBlock
  },
  data() {
    return {

    }
  },
  computed: {
   
  },
  created() {
      
  },
  mounted() {
  },
  updated() {

  },
  methods: {
      
  }
}
</script>

<style>

</style>
