<template>
    <div id="dashboard" class="appContainer" ref="test" v-if="initalDataFromApi && !authKeyApiExpired">
        
        <div v-show="currentPageNumber == 1 || currentPageNumber == 2">
            
            <div id="sharedKeyWarning" v-if="authKeyApiHardcoded && !dashboardMessage">
                <span style="font-weight:600"></span>The current key being used will expire on 3rd May 2021, please visit <code>rtsales.ingka.com</code>.
            </div>

            <div class="top-left">
                <div class="nrt-title">
                    {{ appTitle }}
                    <div class="ikea-logo" v-if="showIkeaLogo">
                        <img alt="IKEA" src="/ikea.svg" />
                    </div> 
                </div>

                <div class="columns nrt-status">
                    <div class="column is-6">
                        <LatestTransaction />
                    </div>
                    <div class="column is-6">
                       <Selection  />
                    </div>
                </div>
                <SalesFigures  />

            </div>

            <div class="right">
                <div class="nrt-title">
                    {{ comparisonGraphTitle }}
                    <div class="last-refresh">
                        <progress max="100" v-bind:value="pageShowTimePercent"></progress>
                        {{ $t('data_info.last_refresh') }}: {{ lastRefresh }}
                    </div>
                </div>
                <ComparisonGraph />
            </div>

            <div class="bottom-left">
                <div class="nrt-title mt">
                    <div>
                        {{ $t('chart_section_titles.sales_channels') }}
                    </div>
                </div>
                <SalesChannelsGraph  />
            </div>
        </div>

        <!-- -------------------------PAGE 3 start------------------------------------------------------------------------------- -->
        <div v-show="currentPageNumber == 3">
            <div class="page3left">
                <div class="nrt-title">
                    {{ appTitle }}
                    <div class="ikea-logo" v-if="showIkeaLogo">
                        <img alt="IKEA" src="/ikea.svg" />
                    </div> 
                </div>
                <CurrentSalesScoreboard v-show="!page3Forbidden" />
            </div>

            <div class="page3right">
                <div class="nrt-title">
                    {{ $t('hourly_page.hourly_sales') }}
                    <div class="last-refresh">
                        <progress max="100" v-bind:value="pageShowTimePercent"></progress>
                        {{ $t('data_info.last_refresh') }}: {{ lastRefresh }}
                    </div>
                </div>
                <div class="graph-title">
                    {{ $t('hourly_page.cumulative') }}
                </div>
                <CumulativeSalesGraph />
                <div class="graph-title">
                    {{ $t('hourly_page.hourly') }}
                </div>
                <HourlyBreakdownSalesGraph />
            </div> 
        </div>
        <!-- -------------------------PAGE 3 end------------------------------------------------------------------------------- -->



        <!-- -------------------------PAGE 4 start------------------------------------------------------------------------------- -->
        <div v-show="currentPageNumber == 4">
            <div class="page4">
                <div class="nrt-title-page4">
                    <div class="nrt-left-title-page4"> 
                        {{ appTitle }} 
                        <div class="ikea-logo" v-if="showIkeaLogo">
                            <img alt="IKEA" src="/ikea.svg" />
                        </div> 
                    </div>

                    <div class="nrt-right-title-page4">
                        {{ $t('top_item_page.top_items_by_store') }} {{page4Selection}}
                    <div class="last-refresh">
                        <progress max="100" v-bind:value="pageShowTimePercent"></progress>
                        {{ $t('data_info.last_refresh') }}: {{ lastRefresh }}
                    </div>
                    </div>
                </div>

                <div class="componentholder-page4">
                    <TopItems  />
                </div>

            </div>
        </div>
        <!-- -------------------------PAGE 4 end------------------------------------------------------------------------------- -->


        <!-- -------------------------PAGE 5 ---------------------------------------------------------------------------------- -->
        <div v-if="currentPageNumber === 5">
            <div id="sharedKeyWarning" v-if="authKeyApiHardcoded && !dashboardMessage">
                <span style="font-weight:600"></span>The current key being used will expire on 3rd May 2021, please visit <code>rtsales.ingka.com</code>.
            </div>

            <div class="top-left">
                <div class="nrt-title">
                    {{ appTitle }}
                    <div class="ikea-logo" v-if="showIkeaLogo">
                        <img alt="IKEA" src="/ikea.svg" />
                    </div> 
                </div>

                <div class="columns nrt-status" v-if="page5loaded">
                    <div class="column is-6">
                        <LatestTransaction :propLatestTransaction="page5latestTransaction"/>
                    </div>
                    <div class="column is-6">
                       <MultiSelection/>
                    </div>
                </div>
                <div v-if="page5loaded">
                    <MultiSalesFigures :salesFigures="$store.state.page5data.data.salesChannels" />
                </div>
            </div>


            <div class="right">
                <div class="nrt-title">
                    {{ comparisonGraphTitle }}
                    <div class="last-refresh">
                        <progress max="100" v-bind:value="pageShowTimePercent"></progress>
                        {{ $t('data_info.last_refresh') }}: {{ lastRefresh }}
                    </div>
                </div>
                <div v-if="page5loaded">
                    <SimpleComparisonGraph :xAxis="page5xAxis" :yAxis="page5yAxis"/>
                </div>
            </div>

            <div class="bottom-left">
                <div id="indicator" v-if="page5loaded">
                    {{ page5message }}
                </div>
            </div>
        </div>
        <!-- -------------------------PAGE 5 end------------------------------------------------------------------------------- -->

        <div v-if="currentPageNumber !== 5">
            <div id="indicator" v-if="dashboardMessage">
                {{ dashboardMessage }}
            </div>
            <div id="indicator" v-else>
                For support / questions, please visit <code>rtsales.ingka.com</code>
            </div>
        </div>
        
        </div>
    <div class="no-content" v-else>
        <div class="expired-key" v-if="authKeyApiExpired">
            <img src="key_expired.svg" />
        </div>
        <div class="loading-screen" v-else-if="apiAccessForbidden">
            {{ $t('data_info.auth_error') }}
        </div>
        <div class="loading-screen" v-else-if="page3Forbidden">
            The supplied key is not valid for hourly forecast data!<br>
            Visit rtsales.ingka.com on laptop/desktop for more information
        </div>
        <div class="loading-screen" v-else-if="ipRestricted">
            The data selected is restricted to certain IP addresses<br>
            Visit rtsales.ingka.com and view this kiosk's configuration for more information
        </div>
        <div class="lds-dual-ring loading-screen" v-else>
        </div>
    </div> 
   
</template>

<script>
import dateFormat from 'dateformat'

import ComparisonGraph from './ComparisonGraphV2.vue'
import Selection from './Selection.vue'
import SalesFigures from './SalesFigures.vue'
import SalesChannelsGraph from './SalesChannelsGraph.vue'
import LatestTransaction from './LatestTransaction.vue'
import CumulativeSalesGraph from './CumulativeSalesGraph.vue'
import HourlyBreakdownSalesGraph from './HourlyBreakdownSalesGraph.vue'
import CurrentSalesScoreboard from './CurrentSalesScoreboard.vue'
import SimpleComparisonGraph from './SimpleComparisonGraph.vue'
import MultiSelection from './MulitSelection.vue';
import MultiSalesFigures from './MultiSalesFigures.vue';

import TopItems from './TopItems.vue'

export default {
  name: 'Dashboard',
  components: {
    ComparisonGraph,
    Selection,
    SalesFigures,
    SalesChannelsGraph,
    LatestTransaction,
    CumulativeSalesGraph,
    HourlyBreakdownSalesGraph,
    CurrentSalesScoreboard,
    TopItems,
    SimpleComparisonGraph,
    MultiSelection,
    MultiSalesFigures
  },
  data() {
    return {
      allowPmaSelect: false,
      initialWindowResizeCalledFromUpdated: false,
      rootElement: null,
      prevFontSize: null,
      prevAppContainerWidth: null,
      resizeTimeoutID: null,
    }
  },
  computed: {
    showIkeaLogo() {
        return this.$store.state.show_ikea_logo;
    },
    initalDataFromApi() {
        return this.$store.state.initalDataFromApi;
    },
    apiAccessForbidden() {
        return this.$store.state.apiAccessForbidden;
    },
    page3Forbidden() {
        return this.$store.state.page3Forbidden;
    },
    ipRestricted() {
        return this.$store.state.ipRestricted;
    },
    currentPage() {
        return this.$store.state.currentPage;
    },
    currentPageNumber() {
        return this.$store.state.currentPageNumber;
    },
    authKeyApiHardcoded() {
        return this.$store.state.authKeyApiHardcoded;
    },
    authKeyApiExpired() {
        return this.$store.state.authKeyApiExpired;
    },
    dashboardMessage() {
        return this.$store.state.dashboardMessage;
    },
    pageShowTimePercent() {
        return this.$store.state.pageShowTimePercent;
    },
    page1Data() {
        return this.$store.state.page1data;
    },
    page2Data() {
        return this.$store.state.page2data;
    },
    page4Selection() {
        return this.$store.state.page4data.selection;
    },
    page5xAxis() {
        const { data } = this.$store.state.page5data;
        return data.comparisonData.map(obj => obj.sale_eur);
    },
    page5yAxis() {
        const { data } = this.$store.state.page5data;
        return data.comparisonData.map(obj => obj.country);
    },
    page5message() {
        return [... new Set (this.$store.state.page5data.data.dashboardMessages.filter(mess => !!mess.message).map(mess => mess.message))].join('')  
    },
    page5latestTransaction() {
        return `${this.$store.state.page5data.data.latestTransaction.latest} - ${this.$store.state.page5data.data.latestTransaction.country}`;
    },
    page5loaded() {
        return this.$store.state.page5data.loaded;
    },
    appTitle() {
        switch (this.currentPageNumber) {
            case 1:
                return this.$t('dash_title.page1')
            case 2:
                return this.$t('dash_title.page2')
            case 3:
            case 4:
                return this.$t('dash_title.page1')
            case 5:
                return this.$t('chart_section_titles.benchmarking');
            default:
                return '';
        }
    },
    comparisonGraphTitle() {
        switch (this.currentPageNumber) {
            case 1:
                return this.$t('chart_section_titles.hfbs');
            case 2:
                return this.$t('chart_section_titles.pmas');
            case 5:
                return this.$t('chart_section_titles.benchmarking');
            default:
                return '';
        }
    },
    lastRefresh()
    {
        return dateFormat(this.$store.state.lastRefresh, "yyyy-mm-dd HH:MM:ss");
    },
    goalValuesEnabled()
    {
        return this.$store.state.goalValuesEnabled;
    },
    page3Teaser()
    {
        return this.$store.state.page3Teaser.enabled;
    },
  },
  created() {
      
  },
  mounted() {
    this.rootElement = document.documentElement;
    window.addEventListener("resize", this.windowResize);
  },
  updated() {
      if (!this.initialWindowResizeCalledFromUpdated)
      {
          this.windowResize();
          this.initialWindowResizeCalledFromUpdated = true;
      }
  },
  methods: {
      appResize(appWidth)
        {
            // the app is a different size
            this.$store.dispatch('setAppContainerWidth', appWidth);

            // resize base font
            const newFontSize = Math.round(appWidth/70);
            if (this.prevFontSize !== newFontSize)
            {
                this.prevFontSize = newFontSize;
                this.rootElement.style.setProperty('font-size', newFontSize + "px");
                this.$store.dispatch('setBaseFontSize', newFontSize);
            }
        },

        windowResize()
        {
            if(typeof this.$refs.test !== 'undefined')
            {
                const heightWidthRatio = 1.95;
                const containerWidthRatio = 0.99;
                const containerHeightRatio = 0.94;

                let potentialContainerWidthFromWindowWidth = containerWidthRatio * window.innerWidth;
                let potentialContainerWidthFromWindowHeight = containerHeightRatio * heightWidthRatio * window.innerHeight;

                let containerWidth = Math.floor(Math.min(potentialContainerWidthFromWindowWidth, potentialContainerWidthFromWindowHeight));
                let containerHeight = Math.floor(containerWidth / heightWidthRatio);

                this.$refs.test.style.setProperty('width', containerWidth + "px");
                this.$refs.test.style.setProperty('height', containerHeight + "px");

                const newAppContainerWidth = this.$refs.test.offsetWidth;

                if (this.prevAppContainerWidth !== newAppContainerWidth)
                {
                    this.prevAppContainerWidth = newAppContainerWidth;
                    this.appResize(newAppContainerWidth);
                }
            }
        },
  }
}
</script>

<style>

html {
    overflow-y: auto;
}

#dashboard {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    color: #2c3e50;
}

.nrt-status{
    margin-left: 0.2em;
    margin-right: 0.2em;
}

.appContainer {
    width: 1900px;
    height: 1000px;
    margin-top: 0.6em;
    margin-left: auto;
    margin-right: auto;
    border: 2px;
    border-style: inset;
    border-color: #aaa;
}

.top-left, .bottom-left {
    width: 42%;
    height: 50%;
    float: left;
}

.top-left {
    font-size: 1rem;
}

.bottom-left {
    font-size: 3rem;
}

.right {
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    float: right;
    margin-left: 1%;
}

.page3left {
    width: 25%;
    height: 50%;
    float: left;
}

.page3right {
    width: 74%;
    height: 100%;
    float: right;
    margin-left: 1%;
}

.page4 {
    width: 100%;
    height: 100%;
}

.componentholder-page4 {
    width: 100%;
    height: 100%; 
}

.test {
    width: 100%;
    height: 400px;
}
.BySalesValueGraph {
    width: 100%;
}
.ByQuantityGraph {
    width: 100%;
}

#indicator {
    text-align: center;
    margin-top: 0.6em;
    font-size: clamp(0.75rem, 1vw, 1rem);
}

.no-content {
    text-align: center;
}

.loading-screen {
  margin-top: 33vh;
}

.mb {
    margin-bottom: 1rem;
}

.mt {
    margin-top: 1rem;
}

.mr-05 {
    margin-right: 0.5rem !important;
}

.nrt-title {
    font-size: 0.7rem;
    background-color: rgb(9, 71, 128);
    color: #ffffff;
    text-transform: uppercase;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.nrt-title-page4 {
    font-size: 0.7rem;
    background-color: rgb(9, 71, 128);
    color: #ffffff;
    text-transform: uppercase;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
}

.nrt-left-title-page4 {
    flex: 35%;
}

.nrt-right-title-page4 {
    flex: 75%;
    padding-left: 5rem;
}

.ikea-logo {
    float: right;
    width: 2.3rem;
}

.graph-title {
    font-size: 0.9rem;
    color: #242424;
    text-transform: uppercase;
    text-align: center;
}

.last-refresh {
    float: right;
    font-size: 0.55rem;
    color: #bbb5d1;
    padding-top: 0em;
}

.v-centered {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    justify-content: center;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid rgb(51, 51, 51);
  border-color: rgb(51, 51, 51) transparent rgb(51, 51, 51) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#sharedKeyWarning {
    text-align: center;
    padding-bottom: 0.15em;
}
</style>