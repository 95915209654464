<template>
    <div class="sales-figures">
        <template v-if="salesFigures.total != null">
            <div v-if="multipleCurrencies" class="multiple-currencies">
                <p>You have selected PMAs with different currencies, hence the summing will not provide useful data.</p>
                <p>Currency conversion is planned for a future release.</p>
            </div>
            <div v-else class="columns is-gapless" :class="{'is-centered': selectedChannels !== 'all'}">
                <div v-if="selectedChannels === 'all'" class="column is-4 is-green-border pma-sales-box mr-05">
                    <div class="is-green-bg mb-05 pma-label-box">
                        <div class="pma-label-images">
                        <img src="/store.png" />
                        <img src="/web.png" />
                        </div>
                        <div class="pma-label-text">
                        {{ $t('sales_channels.total') }} <span class="currency-indicator">({{ currency }})</span>
                        </div>
                    </div>
                    <div class="content mb v-centered">
                        <span>{{ formatNumber(salesFigures.total) }}</span>
                    </div>
                </div>
                <div v-if="selectedChannels === 'all' || selectedChannels.includes('Store')" class="column is-4 is-blue-border pma-sales-box mr-05">
                    <div class="is-blue-bg mb-05 pma-label-box">
                        <div class="pma-label-images">
                        <img src="/store.png" />
                        </div>
                        <div class="pma-label-text">
                        {{ $t('sales_channels.store') }} <span class="currency-indicator">({{ currency }})</span>
                        </div>
                    </div>
                    <div class="content mb v-centered">
                        <span>{{ formatNumber(salesFigures.store) }}</span>
                    </div>
                </div>
                <div v-if="selectedChannels === 'all' || selectedChannels.includes('Online')" class="column is-4 is-orange-border pma-sales-box mr-05">
                    <div class="is-orange-bg mb-05 pma-label-box">
                        <div class="pma-label-images">
                        <img src="/web.png" />
                        </div>
                        <div class="pma-label-text">
                        {{ $t('sales_channels.online_csc') }} <span class="currency-indicator">({{ currency }})</span>
                        </div>
                    </div>
                    <div class="content mb v-centered">
                        <span>{{ formatNumber(salesFigures.online) }}</span>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
var formatNumberGenerator = require('../FormatNumberGenerator');

export default {
    name: 'SalesFigures',
    props: {
    },
    computed: {
        selectedChannels() {
            if (this.$route.path === '/') return 'all';
            const currentPage = this.$store.state.currentPageNumber;
            const all = this.$store.state.kioskConfiguration[`page${currentPage}_all_channels`];
            const channels = this.$store.state.kioskConfiguration[`page${currentPage}_selected_channels`]; 
            if (all) return 'all';
            return channels;
        },
        salesFigures() {
            return this.$store.state.salesChannelsData.salesFigures;
        },
        currency() {
            let currencyList = this.$store.state.currency;
            if (currencyList.length == 1)
            {
                return currencyList[0].code;
            }
            else if (currencyList.length == 0)
            {
                return 'No Currency';
            }
            else
            {
                return 'Combined Currencies';
            }
        },
        multipleCurrencies() {
            return (this.$store.state.currency.length > 1);
        }
    },
    data: function() {
        return {
        }
    },
    mounted() {
    },
    methods: {
        formatNumber(input)
        {
            let formatter = formatNumberGenerator({
                decimal_place: this.$t('html_formatting.decimal_place'),
                thousands_seperator:  this.$t('html_formatting.thousands_seperator')
            })

            return formatter(input)        },
    }
}
</script>

<style scoped>
.pma-label-images img {
    height: 2.2em;
}

.pma-label-images img.csc {
    height: 1.5em;
    margin-left: 0.2em;
    margin-bottom: 0.35em;
}

.pma-label-images, .pma-label-text {
    margin-top: 0;
    margin-bottom: 0;
    height: 50%;
}


.pma-label-text {
    font-size: 0.73em;
    line-height: 0.9;
    padding-top: 0.45em;
}

.pma-label-text .currency-indicator {
    font-size: 0.6em;
}

.pma-label-box {
    color: rgb(255, 255, 255);
    font-size: 1em;
    font-weight: 200;
    padding-left: 0.6em;
    font-family: 'Verdana';
}


.is-green-bg {
    background-color: rgb(36, 176, 9);
}

.is-green-border div {
    border: solid 1px rgb(36, 176, 9);
}

.is-green-border .content {
    color: rgb(36, 176, 9);
}

.is-orange-bg {
    background-color: rgb(255, 138, 35);
}

.is-orange-border div {
    border: solid 1px rgb(255, 138, 35);
}

.is-orange-border .content {
    color: rgb(255, 138, 35);
}

.is-blue-bg {
    background-color: rgb(58, 140, 255);
}

.is-blue-border div {
    border: solid 1px rgb(58, 140, 255);
}

.is-blue-border .content {
    color: rgb(58, 140, 255);
}

.pma-label-box, .content {

    height: 4rem;

    margin-bottom: 0.5rem;
    width: calc(100% - 1rem);
}

.pma-sales-box .content {
    text-align: center;
    font-size: 1.7rem;
}

.sales-figures {
    margin-left: 1em;
    margin-right: 1em;
}

.multiple-currencies {
    background-color: #3f3f3f;
    color: #ffffff;
    padding: 1em;
    font-size: 0.8em;
    text-align: center;
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
