import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        dashboardConfiguration: {
            configuredDashboard: false,
            kioskConfigCode: '',
        },
        kioskConfiguration: {},
        baseFontSize: null,
        appContainerWidth: null,
        initalDataFromApi: false,
        pmaIntervalStarted: false,
        authKey: '',
        authKeyApiHardcoded: false,
        authKeyApiExpired: false,
        dashboardMessage: null,
        apiAccessForbidden: false,
        page3Forbidden: false,
        ipRestricted: false,
        urlParams: '',
        goalValuesEnabledPage1: false,
        goalValuesEnabledPage2: false,
        goalValuesEnabledPage3: false,
        goalValuesEnabledCurrentPage: false,
        lastRefresh: null,
        intervalSeconds: 70,
        pageShowTimePercent: 0,
        currentPageNumber: null,
        show_ikea_logo: false,
        enabledPages: {
            p1: null,
            p2: null,
            p3: null,
            p4: null,
            p5: null
        },
        page3Teaser: {
            eligible: false,
            activeFromApi: false,
            enabled: false,
        },
        currency: [],
        page1data: {
            option: null,
            selection: null,
            dataLoaded: false,
            hfbs: [],
            salesChannels: {
                online: {
                    sales: null,
                    goal: null,
                },
                store: {
                    sales: null,
                    goal: null,
                },
                total: {
                    sales: null,
                    goal: null,
                },
            },
            lastRefresh: null,
            lastTransaction: null,
        },
        page2data: {
            option: null,
            selection: null,
            dataLoaded: false,
            pmas: [],
            salesChannels: {
                online: {
                    sales: null,
                    goal: null,
                },
                store: {
                    sales: null,
                    goal: null,
                },
                total: {
                    sales: null,
                    goal: null,
                },
            },
            lastRefresh: null,
            lastTransaction: null,
        },
        salesChannelsData: {
            selection: null,
            lastTransaction: '',
            salesFigures: {
                online: null,
                store: null,
                total: null
            },
            storeAndOnlineGraphData: [{
                'channel': 'Store',
                'Sales': null,
                'Goal': null,
            },{
                'channel': 'Online',
                'Sales': null,
                'Goal': null,
            },
            ],
            totalGraphData: [{
                'channel': 'Total',
                'Sales': null,
                'Goal': null,
            },
            ],
        },
        page3data: {
            option: null,
            selection: null,
            dailyData: [],
            nextReloadTime: null,
            selectionNames: [],
        },
        page4data: {
            selection: null,
            filterIncludeHfo: true,
            filterIncludeFood: true,
            filterIncludeServices: true,
            filterCompleteSleep: 0,
            topItemsByValue: [],
            topItemsByQuantity: [],
        },
        page5data: {
            selection: null,
            data: {},
            loaded: false
        },
        default_ordering: [1,2,3,4,5],
        configured_ordering: null
    },
    mutations: {
        page3TeaserEligible(context)
        {
            context.page3Teaser.eligible = true;
        },
        page3TeaserEnabledApi(context, enabled)
        {
            context.page3Teaser.activeFromApi = enabled ?? false;
            if (context.page3Teaser.activeFromApi && context.page3Teaser.eligible)
            {
                context.page3Teaser.enabled = true;                
                let pmas = context.page1data.selection.split(',');
                this.commit('initPage3', {option: 'pmas', selection: pmas[0]});
                if(context.goalValuesEnabledPage1)
                {
                    context.goalValuesEnabledPage3 = true;
                }
                context.enabledPages.p3 = true;
            }
        },
        setKioskConfigCode(state, kioskConfigCode)
        {
            state.dashboardConfiguration.configuredDashboard = true;
            state.dashboardConfiguration.kioskConfigCode = kioskConfigCode;
        },
        setConfiguredOrdering(state, payload) {
            const numberPattern = /\d+/g;
            const numbers = payload.split(',');
            const ordering = numbers.map(number => parseInt(number.match(numberPattern)[0]));
            state.configured_ordering = ordering;
        },
        setPage(state, page)
        {
            if (page == 1)
            {
                state.goalValuesEnabledCurrentPage = state.goalValuesEnabledPage1;
            }
            else if (page == 2)
            {
                state.goalValuesEnabledCurrentPage = state.goalValuesEnabledPage2;
            }
            else if (page == 3)
            {
                state.goalValuesEnabledCurrentPage = state.goalValuesEnabledPage3;
            }
            else if (page == 4)
            {
                state.goalValuesEnabledCurrentPage = false;
            }

            state.currentPageNumber = page;
        },
        setEnabledPages(state, enabledPages)
        {
            state.enabledPages = enabledPages;
        },
        enableGoalValuesPage1(state)
        {
            state.goalValuesEnabledPage1 = true;
        },
        enableGoalValuesPage2(state)
        {
            state.goalValuesEnabledPage2 = true;
        },
        enableGoalValuesPage3(state)
        {
            state.goalValuesEnabledPage3 = true;
        },
        enableIkeaLogo(state)
        {
            state.show_ikea_logo = true;
        },
        setBaseFontSize(state, fontSize)
        {
            state.baseFontSize = fontSize;
        },
        setAppContainerWidth(state, width)
        {
            state.appContainerWidth = width;
        },
        apiAccessForbidden(state)
        {
            state.apiAccessForbidden = true;
        },
        page3Forbidden(state)
        {
            state.page3Forbidden = true;
        },
        ipRestricted(state)
        {
            state.ipRestricted = true;
        },
        setAuthKey(state, authKey)
        {
            state.authKey = authKey;
        },
        setIntervalSeconds(state, intervalSeconds)
        {   
            state.intervalSeconds = intervalSeconds;
        },
        initalDataFromApi(state)
        {
            state.initalDataFromApi = true;
        },
        resetPageShowTimePercent(state)
        {
            state.pageShowTimePercent = 0;
        },
        tickPageShowTimePercent(state)
        {
            if(state.pageShowTimePercent < 100)
            {
                state.pageShowTimePercent++;
            }
        },
        updateLastRefresh(context)
        {       
            context.lastRefresh = Date.now();
        },
        savePage1Data(context, payload)
        {   
            context.page1data.hfbs = payload;
            context.page1data.dataLoaded = true;
        },
        savePage2Data(context, payload)
        {   
            context.page2data.pmas = payload;
            context.page2data.dataLoaded = true;
        },
        savePage3Data(context, payload)
        {   
            context.page3data.dailyData = payload.dailyData;
            context.page3data.nextReloadTime = payload.nextReloadTime;
            context.page3data.selectionNames = payload.selection;
        },
        savePage4Data(context, payload)
        {   
            context.page4data.topItemsByValue = payload.topItemsByValue;
            context.page4data.topItemsByQuantity = payload.topItemsByQuantity;
        },
        savePage5Data(context, payload) {
            context.page5data.data = payload;
            context.page5data.loaded = true;
        },
        initPage1(context, payload)
        {   
            context.page1data.option = payload.option;
            context.page1data.selection = payload.selection;
        },
        initPage2(context, payload)
        {
            context.page2data.option = payload.option;
            context.page2data.selection = payload.selection;
        },
        initPage3(context, payload)
        {
            context.page3data.option = payload.option;
            context.page3data.selection = payload.selection;
        },
        initPage4(context, payload)
        {
            context.page4data.selection = payload.selection;
        },
        initPage5(context, payload) {
            context.page5data.selection = payload.selection;
        },
        setCurrency(context, payload)
        {
            context.currency = payload
        },
        saveSalesChannelsData(context, payload)
        {
            context.salesChannelsData.lastTransaction = payload.response.latestTransaction;
            switch (payload.option) {
                case 'pmas':
                    context.salesChannelsData.selection = payload.response.pmaCaps;
                    break;
                case 'country':
                    context.salesChannelsData.selection = payload.response.countryName;
                    break;
                default:
                    context.salesChannelsData.selection = '';
                    break;
            }
            context.salesChannelsData.salesFigures.online = payload.response.online.sale_loc;
            context.salesChannelsData.salesFigures.store = payload.response.store.sale_loc;
            context.salesChannelsData.salesFigures.total = payload.response.total.sale_loc;

            context.salesChannelsData.totalGraphData[0]['Sales'] = payload.response.total.sale_loc;
            context.salesChannelsData.totalGraphData[0]['Goal'] = payload.response.total.goal_loc;

            context.salesChannelsData.storeAndOnlineGraphData[0]['Sales'] = payload.response.online.sale_loc;
            context.salesChannelsData.storeAndOnlineGraphData[0]['Goal'] = payload.response.online.goal_loc;
            context.salesChannelsData.storeAndOnlineGraphData[1]['Sales'] = payload.response.store.sale_loc;
            context.salesChannelsData.storeAndOnlineGraphData[1]['Goal'] = payload.response.store.goal_loc;

            context.authKeyApiHardcoded = payload.response.authKeyHardCoded;
            context.authKeyApiExpired = payload.response.authKeyExpired;
            context.dashboardMessage = payload.response.dashboardMessage;
        },
        setUrlParams(context, payload)
        {
            context.urlParams = payload;
        },
        setKioskConfiguration(context, payload)
        {
            context.kioskConfiguration = payload;
        }
        
    },
    actions: {
        setKioskConfigCode(context, kioskConfigCode)
        {
            context.commit('setKioskConfigCode', kioskConfigCode);
        },
        setEnabledPages(context, enabledPages)
        {
            context.commit('setEnabledPages', enabledPages);
        },
        sendPageConfig(context)
        {
            if (context.state.initalDataFromApi == false)
            {
                axios.post(process.env.VUE_APP_API_URL + '/kioskInit', {
                    urlParams: context.state.urlParams
                })
            }
        },
        setUrlParams(context, params)
        {
            context.commit('setUrlParams', params);
        },
        enableGoalValuesPage1(context)
        {
            context.commit('enableGoalValuesPage1');
        },
        enableGoalValuesPage2(context)
        {
            context.commit('enableGoalValuesPage2');
        },
        enableGoalValuesPage3(context)
        {
            context.commit('enableGoalValuesPage3');
        },
        setBaseFontSize(context, fontSize)
        {
            context.commit('setBaseFontSize', fontSize);
        },
        setAppContainerWidth(context, width)
        {
            context.commit('setAppContainerWidth', width);
        },
        ApiAccessForbidden(context)
        {
            context.commit('apiAccessForbidden');
        },
        setAuthKey(context, authKey)
        {
            context.commit('setAuthKey', authKey);
        },
        setIntervalSeconds(context, intervalSeconds)
        {   
            context.commit('setIntervalSeconds', intervalSeconds);
        },
        initPage1(context, payload)
        {   
            context.commit('initPage1', payload);
        },
        initPage2(context, payload)
        {
            context.commit('initPage2', payload);
        },
        initPage3(context, payload)
        {
            context.commit('initPage3', payload);
        },
        initPage4(context, payload)
        {
            context.commit('initPage4', payload);
        },
        initPage5(context, payload) {
            context.commit('initPage5', payload);
        },
        setPage4Filters(context, payload)
        {
            context.state.page4data.filterIncludeHfo = payload.filterIncludeHfo
            context.state.page4data.filterIncludeFood = payload.filterIncludeFood
            context.state.page4data.filterIncludeServices = payload.filterIncludeServices
            context.state.page4data.filterCompleteSleep = payload.filterCompleteSleep
        },
        updateLastRefresh(context)
        {
            context.commit('updateLastRefresh');
        },
        initPageState(context)
        {
            let nextPage = function() {
                context.dispatch('nextPage')
            };

            nextPage();
            setInterval(nextPage, context.state.intervalSeconds * 1000);

            let tickPageShowTimePercent = function() {
                context.commit('tickPageShowTimePercent')
            };

            tickPageShowTimePercent();
            setInterval(tickPageShowTimePercent, context.state.intervalSeconds * 10)
        },
        refreshPage1(context, nextPage)
        {
            const { page1_all_hfbs = true, page1_selected_hfbs, page1_all_channels = true, page1_selected_channels } = context.state.kioskConfiguration;
            const { option, selection } = context.state.page1data;
            
            const authString = context.getters.apiHttpAuthParams.configCode ? `?configCode=${context.getters.apiHttpAuthParams.configCode}` : `?key=${context.getters.apiHttpAuthParams.key}`;
            const endpoint = `${process.env.VUE_APP_DAILY_SALES_API}/ranges${option === 'country' ? `/country` : `/pmas`}/${selection}/${page1_all_hfbs ? 'all' : page1_selected_hfbs}/${page1_all_channels ? 'all' : page1_selected_channels}${authString}`;
            axios.get(endpoint).then(({ data }) => {
                context.commit('savePage1Data', data.hfbList);
                context.commit('saveSalesChannelsData', {
                    response: { ...data.salesChannels, latestTransaction: data.latestTransaction, selection: data.selection, pmaCaps: data.selection, countryName: data.selection, dashboardMessage: data.dashboardMessage },
                    option: option,
                    selection: data.selection
                });
                context.commit('setCurrency', data.currencyList);
                context.dispatch('sendPageConfig');
                context.commit('initalDataFromApi');
                context.commit('updateLastRefresh');
            })
            .catch(function (error) {
                if (error.response.status == 403)
                {
                    context.commit('apiAccessForbidden');
                }
                else
                {
                    console.log(error);
                }
            });
        },
        refreshPage2(context, nextPage)
        {
            const { page2_all_hfbs = true, page2_selected_hfbs, page2_all_channels = true, page2_selected_channels } = context.state.kioskConfiguration;
            const { option, selection } = context.state.page2data;
            const authString = context.getters.apiHttpAuthParams.configCode ? `?configCode=${context.getters.apiHttpAuthParams.configCode}` : `?key=${context.getters.apiHttpAuthParams.key}`;
            const endpoint = `${process.env.VUE_APP_DAILY_SALES_API}/markets${option === 'country' ? `/country` : `/pmas`}/${selection}/${page2_all_hfbs ? 'all' : page2_selected_hfbs}/${page2_all_channels ? 'all' : page2_selected_channels}${authString}`;
            axios.get(endpoint).then(({ data }) => {
                context.commit('savePage2Data', data.pmaList);
                context.commit('saveSalesChannelsData', {
                    response: { ...data.salesChannels, latestTransaction: data.latestTransaction, selection: data.selection, pmaCaps: data.selection, countryName: data.selection, dashboardMessage: data.dashboardMessage },
                    option: option,
                    selection: selection
                });
                context.commit('setCurrency', data.currencyList);
                context.dispatch('sendPageConfig');
                context.commit('initalDataFromApi');
                context.commit('updateLastRefresh');
            })
            .catch(function (error) {
                if (error.response.status == 403)
                {
                    context.commit('apiAccessForbidden');
                }
                else
                {
                    console.log(error);
                }
            });
        },
        refreshPage3(context) {
            if (this.state.page3Forbidden)
            {
                return
            }

            let now = new Date();
            
            if (now > context.state.page3data.nextReloadTime)
            {
                let nextReloadTime = new Date(now.getTime() + 10*60*1000);
                let apiEndpointPage3 = process.env.VUE_APP_HOURLY_SALES_API_URL + '/' + context.state.page3data.option + '/' + context.state.page3data.selection;

                axios.get(apiEndpointPage3, {
                    params: context.getters.apiHttpAuthParams
                })
                .then(function (page3response) {
                    context.commit('savePage3Data', {nextReloadTime, dailyData: page3response.data.hourlyData, selection: page3response.data.selection})
                    context.commit('initalDataFromApi');
                    context.commit('updateLastRefresh');
                })
                .catch(function (error) {
                    if (error.response.status == 403)
                    {
                        if (error.response.data == 'Shared key is not authorized to load this data')
                        {
                            context.commit('page3Forbidden');
                        }
                        else
                        {
                            context.commit('apiAccessForbidden');
                        }
                    }
                    else
                    {
                        console.log(error);
                    }
                });
                
            }
        },
        refreshPage4(context)
        {
            console.log('t');
            
            let apiEndpointPage4 = process.env.VUE_APP_TOP_ITEMS_API_URL + '/pma/' + context.state.page4data.selection

            axios.get(apiEndpointPage4, {
                params: {...context.getters.apiHttpAuthParams,
                    includeHfo: context.state.page4data.filterIncludeHfo,
                    includeFood: context.state.page4data.filterIncludeFood,
                    includeServices: context.state.page4data.filterIncludeServices,
                    filterCompleteSleep: context.state.page4data.filterCompleteSleep
                }
            })
            .then(function (page4response) {
                context.commit('savePage4Data', page4response.data)
                context.commit('initalDataFromApi');
                context.commit('updateLastRefresh');
            })
            .catch(function (error) {
                if (error.response.status == 403)
                {
                    context.commit('apiAccessForbidden');
                }
                else
                {
                    console.log(error);
                }
            });                  
        },
        refreshPage5(context) {
            const { page5_all_hfbs, page5_selected_hfbs, page5_all_channels, page5_selected_channels } = context.state.kioskConfiguration;
            const { selection } = context.state.page5data;
            const authString = context.getters.apiHttpAuthParams.configCode ? `?configCode=${context.getters.apiHttpAuthParams.configCode}` : `?key=${context.getters.apiHttpAuthParams.key}`;
            const endpoint = `${process.env.VUE_APP_DAILY_SALES_API}/benchmarking/${selection}/${page5_all_hfbs ? 'all' : page5_selected_hfbs}/${page5_all_channels ? 'all' : page5_selected_channels}${authString}`;
            axios.get(endpoint).then(({ data }) => {
                context.commit('savePage5Data', data);
                context.commit('initalDataFromApi');
                context.commit('updateLastRefresh');
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status == 403)
                {
                    context.commit('apiAccessForbidden');
                }
                else
                {
                    console.log(error);
                }
            });

        },
        setPage(context, pageNumber)
        {
            context.commit('setPage', pageNumber)
            switch (pageNumber) {
                case 1:
                    context.dispatch('refreshPage1', '');
                    break;
                case 2:
                    context.dispatch('refreshPage2', '');
                    break;
                case 3:
                    context.dispatch('refreshPage3');
                    break;
                case 4:
                    context.dispatch('refreshPage4');
                    break;
                case 5:
                    context.dispatch('refreshPage5');
                    break
                default:
                    break;
            }
            context.commit('resetPageShowTimePercent');
        },
        nextPage({ dispatch, state }) {
            const selectedPages = state.enabledPages;
            const enabledPages = Object.entries(selectedPages).map(([page, value]) => value ? parseInt(page.split('p')[1]) : '').filter(page => !!page);
            
            if (!state.configured_ordering) {
                const ordering = state.default_ordering.filter(page => enabledPages.includes(page));
                const currentPageIndex = ordering.indexOf(state.currentPageNumber);
                
                if (currentPageIndex === ordering.length - 1) return dispatch('setPage', ordering[0]);
                return dispatch('setPage', ordering[currentPageIndex + 1]);
            } else {
                if (!state.currentPageNumber) return dispatch('setPage', state.configured_ordering[0]);
                const currentPageIndex = state.configured_ordering.indexOf(state.currentPageNumber);
                if (currentPageIndex === state.configured_ordering.length - 1) return dispatch('setPage', state.configured_ordering[0]);
                return dispatch('setPage', state.configured_ordering[currentPageIndex + 1]);
            }

        }
    },

    getters: {
        apiHttpAuthParams(state)
        {
            let params = {}

            if (state.dashboardConfiguration.configuredDashboard)
            {
                params.configCode = state.dashboardConfiguration.kioskConfigCode
            }
            else
            {
                params.key = state.authKey
            }

            return params
        },
    },
})