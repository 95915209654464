<template>
  <div class="store-select">
    <div class="pma-label">{{ $t('data_info.page_selection') }}:<br></div>
    <div id="pmaSelection" class="pma-selection">{{$store.state.page5data.data.salesChannels.map(data => data.country).join(', ')}}</div> 

  </div>
</template>

<script>

export default {
    name: 'MultiSelection',
    watch: {
        selectionFontSize(newVal) {
          document.getElementById('pmaSelection').style.setProperty('font-size', newVal);
        },
    },
}
</script>

<style scoped>

.pma-label {
  font-size: 0.7em;
  color: #b3b3b3;
  text-transform: uppercase;
}

.pma-selection {
  font-size: 0.7rem;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>