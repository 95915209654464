<template>
  <div id="graph-container" class="hello">
    <div id="container" style="height: 600px; width: 600px;"></div>
  </div>
</template>

<script>
const echarts = require('echarts');
const formatNumberGenerator = require('../FormatNumberGenerator');
const salesAndGoalLegendTranslation = require('../SalesAndGoalLegendTranslation');
let eChartHfbs;

export default {
    name: 'ComparisonGraph',
    data() {
        const translations = {sales: this.$t('chart_keys.sales'), goal: this.$t('chart_keys.goal')};
        const salesAndGoalLegendFormatter = salesAndGoalLegendTranslation(translations);
        let formatNumber = this.formatNumber;
        return {
            scrollDown: true,
            scrollingInterval: null,
            scrollable: false,
            translations,
            numberOfItemsInChart: 0,
            hfbChartOptions: {
                legend: {
                    formatter: salesAndGoalLegendFormatter,
                    left: '10%',
                },
                title: {},
                textStyle: {
                    fontSize: '',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                distance: {
                    min: 0,
                    max: 100
                },
                grid: {
                    left: '4%',
                    right: '9%',
                    bottom: '3%',
                    containLabel: true
                },
                yAxis: {
                    type: 'category',
                    axisLabel: {
                        fontSize: '',
                        interval: 0,
                        overflow: 'truncate'
                    },
                },
                xAxis: {
                    type: 'value',
                    splitLine: {
                        lineStyle: {
                            width: 0.4,
                            type: 'dotted'
                        },
                    },
                    axisLabel: {
                        formatter: function (value, index)
                        {
                            return formatNumber(value)
                        },
                        fontSize: ''
                    },
                },
                series: [
                    {
                        type: 'bar',
                        itemStyle: {
                            color: 'rgba(30, 161, 90, 1)',
                        },
                        barWidth: '',
                        label: {
                            show: true,
                            formatter: this.salesBarTitleCallback,
                            color: 'rgba(0, 0, 0, 1)',
                            position: 'right',
                            distance: 7,
                            padding: [0, 0, 0, 0],
                            fontWeight: '',
                            fontSize: '',
                            verticalAlign: ''
                        },
                    },

                ]
            },
            hfbChartOptionsSeriesGoal: {
                type: 'bar',
                barGap: '0%',
                barWidth: '',
                itemStyle: {
                    color: 'rgba(217, 179, 0, 1)',
                },
                label: {
                    show: true,
                    formatter: this.goalBarTitleCallback,
                    color: 'rgba(0, 0, 0, 1)',
                    position: 'right',
                    distance: 7,
                    fontWeight: '',
                    fontSize: '',
                    verticalAlign: ''
                },
            }
        }
    },
    methods: {
        convertThousandsSeparator(input){
            return input.replaceAll(',', this.$t('html_formatting.thousands_seperator'))
        },
        goalBarTitleCallback(object) {
            return this.convertThousandsSeparator(typeof object.data === 'object' ? object.data.value.toLocaleString() : object.data.toLocaleString());
        },
        salesBarTitleCallback(object) {
            return this.convertThousandsSeparator(typeof object.data === 'object' ? object.data.value.toLocaleString() : object.data.toLocaleString());
        },    
        rgba(options) {
            if (options === undefined) options = {};

            if (options.colour != undefined) {
                options.red = options.colour.red;
                options.green = options.colour.green;
                options.blue = options.colour.blue;
            } else {
                if (options.red === undefined) options.red = 0;
                if (options.green === undefined) options.green = 0;
                if (options.blue === undefined) options.blue = 0;
            }

            if (options.alpha === undefined) options.alpha = 1;

            return `rgba(${options.red}, ${options.green}, ${options.blue}, ${options.alpha})`;
        },
        setChartFontSize(setOption=true, scrollable = false) {
            const newAppWidth = this.appContainerWidth;
            const numItemsInChart = scrollable ? Math.floor(this.hfbChartOptions.series[0].data.length / 10) : this.hfbChartOptions.series[0].data.length;
            const itemFontSize = Math.round(newAppWidth / Math.max((3.45 * numItemsInChart), 120));

            this.hfbChartOptions.textStyle.fontSize = Math.round(newAppWidth / 170);


            this.hfbChartOptions.yAxis.axisLabel.fontSize = itemFontSize;
            this.hfbChartOptions.series[0].label.fontSize = itemFontSize;
            if (this.hfbChartOptions.series[1] !== null) this.hfbChartOptions.series[1].label.fontSize = itemFontSize;
            
            if (setOption) eChartHfbs.setOption(this.hfbChartOptions, true);
        },
        pageDataToChartSource() {
            let chartSource = [];

            const page1 = (this.currentPageNumber === 1);

            const comparisonData = page1 ? this.page1data.hfbs : this.page2data.pmas;


            if (comparisonData.length > 25 && this.$store.state.kioskConfiguration[`page${this.currentPageNumber}_graph_scroll`]) this.scrollable = true;

            const yAxisData = [];
            const salesData = [];
            const goalsData = [];
            
            comparisonData.forEach(comparisonItem => {
                let comparisonItemName = '';

                if (page1) {
                    const hfbKey = 'hfb_names.' + comparisonItem.hfb_no;
                    if (this.$te(hfbKey)) {
                        comparisonItemName = comparisonItem.hfb_no + ' - ' + this.$t(hfbKey);
                    } else {
                        comparisonItemName = comparisonItem.hfb_cap;
                    }
                } else {
                    comparisonItemName = comparisonItem.pma_cap;
                }

                let comparisonItemChartData = {
                    'comparisonItem': comparisonItemName,
                    'Sales': (comparisonItem.sale_loc > 1) ? comparisonItem.sale_loc : null,
                };

                if (this.goalValuesEnabledCurrentPage) comparisonItemChartData['Goal'] = (comparisonItem.goal_loc > 1) ? comparisonItem.goal_loc : null;
                
                chartSource.push(comparisonItemChartData);
            });

            const order = this.$store.state.kioskConfiguration.page2_market_order;

            const data = chartSource.reverse();
            if (order && this.$store.state.currentPageNumber === 2) {
                data.sort((a, b) => {
                    if (order === 1) {
                        if (a.Sales < b.Sales) {
                            return -1;
                        } else if (a.Sales > b.Sales) {
                            return 1;
                        } else {
                            return 0;
                        }
                    } else {
                        return parseInt(a.comparisonItem.slice(0,2)) < parseInt(b.comparisonItem.slice(0,2));
                    }
                })
            }

            const page1pmas = this.page1data.option === 'pmas' ? this.page1data.selection.split(',') : [];

            data.forEach(dataItem => {
                if (this.$store.state.currentPageNumber === 2 && page1pmas.length && page1pmas.some(pma => dataItem.comparisonItem.includes(pma))) {

                    const newAppWidth = this.appContainerWidth;
                    const numItemsInChart = this.hfbChartOptions.series[0].data ? this.hfbChartOptions.series[0].data.length : 0;
                    const itemFontSize = Math.round(newAppWidth / Math.max((5 * numItemsInChart), 155));

                    yAxisData.push({
                        value: dataItem.comparisonItem,
                        textStyle: {
                            color: 'rgb(9, 71, 128)',
                            fontWeight: 'bold',
                            fontSize: itemFontSize + 1
                        }
                    })
                } else {
                    yAxisData.push(dataItem.comparisonItem); 
                }

                salesData.push(dataItem.Sales);
                goalsData.push(dataItem.Goal);   

            });

            this.hfbChartOptions.yAxis.data = yAxisData;
            this.hfbChartOptions.series[0].data = salesData;
            this.hfbChartOptions.series[0].name = this.translations.sales;

            this.goalValuesEnabledCurrentPage ?
                this.hfbChartOptions.series[1] = {
                    name: this.translations.goal,
                    data: goalsData,
                    ...this.hfbChartOptionsSeriesGoal
                } :
                this.hfbChartOptions.series[1] = null;
        },
        updateChart() {
            this.scrollable = false;
            const container = document.querySelector('#graph-container');
            container.scroll(0,0);
            this.updateChartSize(this.appContainerWidth);
            if (this.scrollingInterval) clearInterval(this.scrollingInterval);
            this.pageDataToChartSource();
            this.numberOfItemsInChart = this.hfbChartOptions.series[0].data.length;
            this.setChartFontSize(true, this.scrollable);
            eChartHfbs.setOption(this.hfbChartOptions, {notMerge: true});
            if (this.scrollable) this.makeGraphScrollable();
        },
        pagesDataChanged() {
            this.updateChart();
        },
        formatNumber(input) {
            const formatter = formatNumberGenerator({
                decimal_place: this.$t('html_formatting.decimal_place'),
                thousands_seperator:  this.$t('html_formatting.thousands_seperator')
            })

            return formatter(input)
        },
        makeGraphScrollable() {
            const container = document.querySelector('#graph-container');
            const graph = this.$el.querySelector('#container');

            const chartWidthFactor = 0.55;
            const chartHeightFactor = 0.45;
            const newContainerHeight = Math.floor(this.appContainerWidth * chartHeightFactor);

            container.style.maxHeight = `${newContainerHeight}px`;
            container.style.overflowY = 'scroll';


            const newGraphWidth = Math.floor(this.appContainerWidth * chartWidthFactor);
            const newGraphHeight = Math.floor(this.appContainerWidth * chartHeightFactor * (6/2));

            // container.style.height = (newContainerHeight)+"px";
            graph.style.height = (newGraphHeight)+"px";
            graph.style.width = (newGraphWidth)+"px";
            eChartHfbs.resize({width: newGraphWidth, height: newGraphHeight});
            this.setChartFontSize(true, true);


            if (this.scrollingInterval) clearInterval(this.scrollingInterval);

            this.scrollingInterval = setInterval(() => {
                if (Math.floor(container.scrollHeight - container.scrollTop) === container.clientHeight) {
                    this.scrollDown = false;
                }
                if (container.scrollTop === 0) {
                    this.scrollDown = true;
                }
                if (this.scrollDown) container.scrollBy(0, 1);
                else container.scrollBy(0, -1);
            }, 50)

        },
        updateChartSize(newAppWidth) {
            const container = document.getElementById('container')

            const chartWidthFactor = 0.56;
            const chartHeightFactor = 0.45;
            
            const newChartWidth = Math.floor(newAppWidth * chartWidthFactor);
            const newChartHeight = Math.floor(newAppWidth * chartHeightFactor);

            container.style.width = (newChartWidth)+"px";
            container.style.height = (newChartHeight)+"px";
            this.setChartFontSize();

            eChartHfbs.resize({width: newChartWidth, height: newChartHeight});
            eChartHfbs.setOption(this.hfbChartOptions, true);

            if (this.scrollable) this.makeGraphScrollable();
        }
    },
    computed: {
        page1data() {
            return this.$store.state.page1data;
        },
        page2data() {
            return this.$store.state.page2data;
        },
        currentPage() {
            return this.$store.state.currentPage;
        },
        currentPageNumber() {
            return this.$store.state.currentPageNumber;
        },
        appContainerWidth() {
            return this.$store.state.appContainerWidth;
        },
        goalValuesEnabled() {
            return this.$store.state.goalValuesEnabledPage1;
        },
        goalValuesEnabledCurrentPage() {
            return this.$store.state.goalValuesEnabledCurrentPage;
        }
    },
    mounted() {
        eChartHfbs = echarts.init(document.getElementById('container'));
        this.pageDataToChartSource();
        this.setChartFontSize(false, this.scrollable);
        eChartHfbs.setOption(this.hfbChartOptions, true);
        if (this.scrollable) this.makeGraphScrollable();

    },
    destroyed() {
        if (this.scrollingInterval) clearInterval(this.scrollingInterval);
    },
    watch: {
        appContainerWidth(newAppWidth) {
            this.updateChartSize(newAppWidth);
        },
        currentPage() {
            this.updateChart();
        },
        currentPageNumber() {
            this.updateChart();
        },
        page1data: {
            handler() {
                this.pagesDataChanged();
            },
            deep: true,
        },
        page2data: {
            handler() {
                this.pagesDataChanged();
            },
            deep: true,
        },
    },
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.hello {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.hello::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
</style>
