<template>
  <div style="position: absolute; z-index: 1000;">
    <!-- checking -->
    <div v-if="showChecking" class="check-block">
      <div v-if="showCheckingLoading" class="loader"></div>

      <!-- fail mark to prepare fail message -->
      <div v-if="!showCheckingLoading" class="cross-container">
        <div v-if="!showCheckingLoading" class="cross"></div>
      </div>
      <div class="check-message">
        <p>We are currently checking your device for compatibility with an upcoming security enhancement update.</p>
      </div>
    </div>


    <!-- warning block -->
    <div v-if="isWarningVisible" class="warning-block">
      <div class="warning-container">
        <div class="info-container">
          <div>
            <p>Hej!</p>
            <br>
            <p>We are excited to announce that the new and improved Real-Time Sales Dashboard is scheduled for release on December 15, 2024. To ensure a seamless experience, we recommend reviewing the compatibility guidelines outlined in <a href="https://confluence.build.ingka.ikea.com/display/SBPDI1/Realtime+Sales+Dashboard+Kiosk+Security+Enhancement" target="_blank">confluence</a> or scan the QR code to the right to reach the confluence page. For any further queries please reach out to our <a href="https://jira.digital.ingka.com/servicedesk/customer/portal/43" target="_blank">Jira Service Desk</a>.</p>
            <br>
            <p>Note: This is not applicable to the reports on PowerBI they continue to function as normal.</p>
            <br>
            <p>Best Regards,</p>
            <p>Team Decision Intelligence</p>
            <p>Strategy and Business Planning, Business Support.</p>
          </div>
        </div>
        <div class="qr-and-close-container">
          <button @click="isWarningVisible = false" class="close-button">Close({{ secondsToAutoClose }})</button>
          <img :src="qrCodeImage" alt="Confluence Page QR Code">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WarningBlock',
  data() {
    return {
      secondsToAutoClose: 30,
      isWarningVisible: false,
      showChecking: true,
      showCheckingLoading: true,
      qrCodeImage: require('@/assets/confluence_page_qr.png'), // Adjust the path as necessary
    };
  },
  methods: {
  },
  async mounted()  {
    
    setTimeout(() => {
      this.showCheckingLoading = false;
      this.isWarningVisible = true;
      this.showChecking = false;

      setInterval(() => {
        this.secondsToAutoClose--;
      }, 1000);
      
      setTimeout(() => {
        this.isWarningVisible = false;
      }, (this.secondsToAutoClose * 1000)); 

    }, 3000);

  },
}
</script>

<style scoped>
.check-message {
  width: 600px;
}

div > a {
  text-decoration: underline;
}

div > p {
  font-size: 16px;
}

.cross-container{
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.cross {
  width: 20px;
  height: 20px;
  display: block;
  position: relative;
  transform: rotate(45deg); /* Optional: Adds a diagonal orientation */
}

.cross:before, .cross:after {
  content: '';
  position: absolute;
  background-color: #FF0000; /* Red */
  width: 20px;
  height: 2px;
  top: 9px;
  left: 0;
}

.cross:after {
  transform: rotate(90deg);
}

.check-block{
  position: fixed;
  width: 700px;
  right: 10px;
  bottom: 10px;
  background-color: #f3f3f3;
  color: black;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}


.checkmark {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  position: relative;
  background-color: #4CAF50; 
}

.checkmark:after {
  content: '';
  position: absolute;
  left: 15px;
  top: 7px;
  width: 10px;
  height: 20px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.success-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 700px;
  right: 10px;
  bottom: 10px;
  background-color: rgb(66, 185, 131);
  color: black;
  padding: 10px;
  
}

.qr-and-close-container{
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
}

.links {
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button{
  background-color: #f44336;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  margin-bottom: 10px;
}

.info-container{
  display: flex;
  flex-direction: column;
}

.warning-container {
  display: flex;
  flex-direction: row;
  align-items: unset;
}

.warning-block {
  position: fixed;
  width: 800px;
  right: 10px;
  bottom: 10px;
  background-color: rgb(255, 153, 0);
  color: black;
  padding: 10px;
}

.warning-block img {
  max-width: 150px;
  max-height: 150px;
}
</style>