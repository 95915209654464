<template>
  <div>

    <div class="filterContainer">
      <div>
        {{ $t('top_item_page.range_offer') }}:
        <button type="button" :disabled="!selectedFilters.hfo" class="filterButtons"> {{ $t('top_item_page.hfo') }} </button>
        <button type="button" :disabled="!selectedFilters.food" class="filterButtons"> {{ $t('top_item_page.food') }} </button>
        <button type="button" :disabled="!selectedFilters.services" class="filterButtons"> {{ $t('top_item_page.services') }} </button>
      </div>
      <div style="padding-right: 16px;">
        {{ filteredBy }}
      </div>
    </div>

    <div class="salesTitleBox">
       {{ $t('top_item_page.top_item_by_sale') }} 
    </div>

    <div class="salesContainer">
 
          <BySalesValueTable />

      <div class="containerColumnRight">

            <BySalesValueGraph /> 
    
      </div>

    </div>

    <div class="quantityTitleBox">
      {{ $t('top_item_page.top_item_by_qty') }}
    </div>

    <div class="quantityContainer">

        <ByQuantityTable />

      <div class="containerColumnRight">
        <ByQuantityGraph />
      </div>

    </div>
  </div>
</template>


<script>
import BySalesValueGraph from './BySalesValueGraph.vue'
import ByQuantityGraph from './ByQuantityGraph.vue'
import BySalesValueTable from './BySalesValueTable.vue'
import ByQuantityTable from './ByQuantityTable.vue'


export default {
  name: 'TopItems',
  components: {
    BySalesValueGraph,
    ByQuantityGraph,
    BySalesValueTable,
    ByQuantityTable
  },
  methods: {
    
  },
  data() {
      return {}
  },
  computed: {
    selectedFilters() {
      return {
        hfo: this.$store.state.page4data.filterIncludeHfo,
        food: this.$store.state.page4data.filterIncludeFood,
        services: this.$store.state.page4data.filterIncludeServices,
        completeSleep: this.$store.state.page4data.filterCompleteSleep,
      }
    }, 
    filteredBy() {
      if (!this.$store.state.page4data || !this.$store.state.page4data.filterCompleteSleep) {
        return null;
      }
      let filteredBy = '';
      if (this.$store.state.page4data.filterCompleteSleep & 1) {
        filteredBy += 'Contributing';
      } 
      if (this.$store.state.page4data.filterCompleteSleep & 2) {
        filteredBy = filteredBy ? filteredBy + ', ' : '';
        filteredBy = filteredBy + 'Core';
      }
      if (this.$store.state.page4data.filterCompleteSleep & 4) {
        filteredBy = filteredBy ? filteredBy + ', ' : '';
        filteredBy = filteredBy + 'Not Applicable';
      }
      filteredBy = filteredBy ? 'Showing items that are tagged with: ' + filteredBy : '';
      return filteredBy;
    }
  },
  mounted() {
      
  },
  watch: {

      },
  }

</script>

<style scoped>

.filterContainer {
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 100%;
  text-align: right;
  font-weight: bold;
  font-size: 0.5rem;
  padding-right: 3.2%;
}

.salesTitleBox {
  font-weight: 900;
  font-size: 0.7rem;
  padding-left: 1%;
}

.salesContainer {
  width: 100%;
  display: grid;
  padding-left: 1%;
  padding-right: 1%;
  grid-template-columns: 60% 40%;
  grid-template-rows: auto; 
}

.quantityTitleBox {
  font-weight: 900;
  font-size: 0.7rem;
  padding-left: 1%;
}

.quantityContainer {
  width: 100%;
  display: grid;
  padding-left: 1%;
  padding-right: 1%;
  grid-template-columns: 60% 40%;
  grid-template-rows: auto;
}

/* ----------- Filter ----------- */

.filterButtons {
  box-shadow: inset 0px 1px 0px 0px #7a8eb9;
  background-color:rgb(9, 71, 128);
  border: 1px solid black;
  display:inline-block;
  color:#ffffff;
  font-family:Arial;
  font-size: 0.55rem;
  font-weight: 900;
  padding: 4px 69px;
  margin-left: 6px;
  margin-right: 6px;
	text-decoration: none;
}

button[type=button]:disabled {
  background: #dddddd;
  color:rgb(141, 139, 139);
}

</style>