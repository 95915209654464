<template>
  <div class="store-select">
    <div class="pma-label">{{ $t('data_info.page_selection') }}:<br></div>
    <div id="pmaSelection" class="pma-selection" v-html="selection"></div> 

  </div>
</template>

<script>

export default {
    name: 'Selection',
    props: {
    },
    methods: {
      updateSelectionListFontSize() {
      }
    },
    computed: {
        selection() {
            let selection = this.$store.state.salesChannelsData.selection;
            if (!selection) return '';
            let numberOfSelections;
            let numberOfSelectionsAdded = 0;
            const numberOfSelectionsToShow = 5;
            let returnString = '';
            switch (typeof selection) {
                case 'string':
                    returnString = selection;
                    break;
                case 'object':
                    numberOfSelections = Object.keys(selection).length;
                    if (numberOfSelections == 1) {
                      returnString = selection[0];
                      break;
                    }

                    selection.forEach(selectionItem => {
                        if (numberOfSelectionsAdded < numberOfSelectionsToShow) {
                            numberOfSelectionsAdded++;
                            returnString += selectionItem.substring(0, 3);
                            if (numberOfSelectionsAdded < numberOfSelections) {
                              returnString += ', ';
                            }
                        }
                    })
                    if ((numberOfSelectionsAdded == numberOfSelectionsToShow) && (numberOfSelectionsToShow != numberOfSelections)) {
                        let numberOfExtraPmas = numberOfSelections - numberOfSelectionsAdded;
                        let extraString = this.$t('data_info.selection_extra')
                        extraString = extraString.replace('$', numberOfExtraPmas)

                        returnString += extraString;
                    }
                    break;
                default:
                    console.log('salesChannelsData.selection was ' + typeof selection + ':')
                    break;
            }
            return returnString;
        },
    },
    data: function() {
        return {
        }
    },
    mounted() {
    },
    watch: {
        selectionFontSize(newVal) {
          document.getElementById('pmaSelection').style.setProperty('font-size', newVal);
        },
    },
}
</script>

<style scoped>

.pma-label {
  font-size: 0.7em;
  color: #b3b3b3;
  text-transform: uppercase;
}

.pma-selection {
  font-size: 0.7rem;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>