<template>
  <div class="hello">
    <div id="cumulativeSalesChart" style="height: 400px; width: 1000px;"></div>
  </div>
  
</template>

<script>

var echarts = require('echarts');
var formatNumberGenerator = require('../FormatNumberGenerator');
var eChartCumulative;

export default {
    name: 'CumulativeSalesGraph',
    props: {
        msg: String
    },
    computed: {
        dailyData() {
            return this.$store.state.page3data.dailyData;
        },
        appContainerWidth() {
            return this.$store.state.appContainerWidth;
        },
        goalValuesEnabled()
        {
            return this.$store.state.goalValuesEnabledPage3;
        },
    },
    data() {
        let formatNumber = this.formatNumber;
        return {
            chartSeriesForGoal: {
                data: [],
                type: 'line',
                smooth: true,
                name: this.$t('hourly_page.goal'),
                lineStyle: {
                    width: 5,
                },
                markLine: {
                    symbol: 'none',
                    lineStyle: {
                        width: 3,
                    },
                    label: {
                        position: 'middle',
                        formatter: this.$t('hourly_page.goal_eod'),
                        fontSize: 14,
                    },
                    data: [
                        {
                            type: 'max',
                        }
                    ]
                }
            },
            chartLegendDataForGoal: {
                name: this.$t('hourly_page.goal'),
                icon: 'circle'
            },
            chartOptions: {
                color: [
                    'rgba(204,0,0, 1)',
                    'rgba(214, 214, 214, 1)',
                    'rgba(74,134,232, 1)',
                    'rgba(255,153,0, 1)',
                    'rgba(74,134,232, 1)',
                    'rgba(255,153,0, 1)',
                ],
                grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '0%',
                    top: '5%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    axisTick: {
                        show: false,
                    },
                    data: [],
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: function (value, index)
                        {
                            return formatNumber(value)
                        },
                    },
                },
                legend: {
                    data: [
                        {
                            name: this.$t('hourly_page.hist_avg_sale'),
                            icon: 'roundRect'
                        },
                        {
                            name: this.$t('hourly_page.sale_today'),
                            icon: 'circle'
                        },
                        {
                            name: this.$t('hourly_page.sale_estm'),
                            icon: 'circle'
                        },
                    ],
                },
                series: [
                    {
                        data: [],
                        type: 'line',
                        smooth: true,
                        name: this.$t('hourly_page.sale_today'),
                        lineStyle: {
                            width: 5,
                        },
                    },
                    {
                        data: [],
                        type: 'bar',
                        name: this.$t('hourly_page.hist_avg_sale'),
                        barGap: '0%',
                        barWidth: 10,
                        itemStyle: {
                        },
                    },
                    {
                        data: [],
                        type: 'line',
                        smooth: true,
                        name: this.$t('hourly_page.sale_estm'),
                        lineStyle: {
                            width: 5,
                        },
                        markLine: {
                            symbol: 'none',
                            lineStyle: {
                                width: 3,
                            },
                            label: {
                                position: 'middle',
                                formatter: this.$t('hourly_page.estm_sale_eod'),
                                fontSize: 14,
                            },
                            data: [
                                {
                                    type: 'max',
                                }
                            ]
                        }
                    },
                ]
            },
        };
    },
    mounted() {
        if (this.goalValuesEnabled)
        {
            this.addGoalData()
        }
        
        eChartCumulative = echarts.init(document.getElementById('cumulativeSalesChart'));
        if (this.$store.state.page3data.nextReloadTime !== null)
        {
            this.convertData();
        }
    },
    methods: {
        addGoalData()
        {
            this.chartOptions.legend.data.push(this.chartLegendDataForGoal);
            this.chartOptions.series.push(this.chartSeriesForGoal);
        },
        convertData()
        {
            this.chartOptions.xAxis.data = [];
            this.chartOptions.series[0].data = [];
            this.chartOptions.series[1].data = [];
            this.chartOptions.series[2].data = [];

            if (this.goalValuesEnabled)
            {
                this.chartOptions.series[3].data = [];
            }
            
            this.dailyData.forEach((halfHour) => {
                this.chartOptions.xAxis.data.push(halfHour.tm);
                this.chartOptions.series[0].data.push(halfHour.sale);
                this.chartOptions.series[1].data.push(halfHour.sale_hist);
                this.chartOptions.series[2].data.push(halfHour.sale_forecast);

                if (this.goalValuesEnabled)
                {
                    this.chartOptions.series[3].data.push(halfHour.goal);
                }
            })
            eChartCumulative.setOption(this.chartOptions, {notMerge: true});
        },
        formatNumber(input)
        {
            let formatter = formatNumberGenerator({
                decimal_place: this.$t('html_formatting.decimal_place'),
                thousands_seperator:  this.$t('html_formatting.thousands_seperator')
            })

            return formatter(input)
        },
    },
    watch: {
        dailyData: {
            handler(newValue) {
                this.convertData();
            },
            deep: true,
        },
        appContainerWidth(newAppWidth) {
            const container = document.getElementById('cumulativeSalesChart')

            const chartWidthFactor = 0.70;
            const chartHeightFactor = 0.31;
            
            const newChartWidth = Math.floor(newAppWidth * chartWidthFactor);
            const newChartHeight = Math.floor(newAppWidth * chartHeightFactor);

            container.style.width = (newChartWidth)+"px";
            container.style.height = (newChartHeight)+"px";

            eChartCumulative.resize({width: newChartWidth, height: newChartHeight});
        },
    },
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.hello {
    margin-bottom: 0.5rem;
}
</style>
