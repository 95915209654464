<template>
  <div class="sales-figures">
    <div v-if="multipleCurrencies" class="multiple-currencies">
      <p>
        You have selected PMAs with different currencies, hence the summing will
        not provide useful data.
      </p>
      <p>Currency conversion is planned for a future release.</p>
    </div>
    <div class="columns is-gapless" :class="{'is-centered': selectedChannels !== 'all'}">
      <div v-if="selectedChannels === 'all'" class="column is-4 is-green-border pma-sales-box mr-05">
        <div class="is-green-bg pma-label-box topper">
          <div class="pma-label-images">
            <img src="/store.png" />
            <img src="/web.png" />
          </div>
          <div class="pma-label-text">
            {{ $t("sales_channels.total") }} (EUR)
          </div>
        </div>
        <div id="total-content" class="content">
          <div class="columns data-list" v-for="(tot, i) in total" :key="i">
            <div class="column is-half country">
              {{ breakword(countries[i]) }}
            </div>
            <div class="column is-half is-centered">
              {{ formatNumber(tot.sale_eur) }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectedChannels === 'all' || selectedChannels.includes('Store')" class="column is-4 is-blue-border pma-sales-box mr-05">
        <div class="is-blue-bg pma-label-box topper">
          <div class="pma-label-images">
            <img src="/store.png" />
          </div>
          <div class="pma-label-text">
            {{ $t("sales_channels.store") }} (EUR)
          </div>
        </div>
        <div id="store-content" class="content">
          <div class="columns data-list" v-for="(sto, i) in store" :key="i">
            <div class="column is-half country">
              {{ breakword(countries[i]) }}
            </div>
            <div class="column is-half is-centered">
              {{ formatNumber(sto.sale_eur) }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectedChannels === 'all' || selectedChannels.includes('Online')" class="column is-4 is-orange-border pma-sales-box mr-05">
        <div class="is-orange-bg pma-label-box topper">
          <div class="pma-label-images">
            <img src="/web.png" />
          </div>
          <div class="pma-label-text">
            {{ $t("sales_channels.online_csc") }} (EUR)
          </div>
        </div>
        <div id="online-content" class="content">
          <div class="columns data-list" v-for="(onl, i) in online" :key="i">
            <div class="column is-half country">
              {{ breakword(countries[i]) }}
            </div>
            <div class="column is-half is-centered">
              {{ formatNumber(onl.sale_eur) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var formatNumberGenerator = require("../FormatNumberGenerator");

export default {
  name: "SalesFigures",
  props: {
    salesFigures: Array,
  },
  data() {
    return {
      scrollDown: true,
      intervals: [{ interval: null, previousScrollTop: null }, { interval: null, previousScrollTop: null }, { interval: null, previousScrollTop: null }],
    };
  },
  computed: {
    selectedChannels() {
        const currentPage = this.$store.state.currentPageNumber;
        const all = this.$store.state.kioskConfiguration[`page${currentPage}_all_channels`];
        const channels = this.$store.state.kioskConfiguration[`page${currentPage}_selected_channels`]; 
        if (all) return 'all';
        return channels;
    },
    currency() {
      let currencyList = this.$store.state.currency;
      if (currencyList.length == 1) {
        return currencyList[0].code;
      } else if (currencyList.length == 0) {
        return "No Currency";
      } else {
        return "Combined Currencies";
      }
    },
    countries() {
      return [...new Set(this.salesFigures.map((sale) => sale.country))];
    },
    online() {
      return [...new Set(this.salesFigures.map((sale) => sale.online))];
    },
    store() {
      return [...new Set(this.salesFigures.map((sale) => sale.store))];
    },
    total() {
      return [...new Set(this.salesFigures.map((sale) => sale.total))];
    },
    multipleCurrencies() {
      return this.$store.state.currency.length > 1;
    },
    isContentScrollable() {
      const { scrollHeight: height } = document.querySelectorAll("[id$='-content']")[0];
      return height > 300;
    },
    interval() {
      return this.$store.state.kioskConfiguration.interval;
    },
  },
  methods: {
    formatNumber(input) {
      let formatter = formatNumberGenerator({
        decimal_place: this.$t("html_formatting.decimal_place"),
        thousands_seperator: this.$t("html_formatting.thousands_seperator"),
      });

      return formatter(input);
    },
    breakword(word) {
      if (!word) return "";
      let newWord = "";
      word.split(" ").forEach((subWord) => {
        if (subWord.length > 8) {
          newWord += subWord.substr(0, 8);
          const nextPart = this.breakword(subWord.slice(8));
          if (nextPart) newWord += `-${nextPart}`;
        } else {
          newWord += subWord;
        }
        newWord += " ";
      });
      return newWord;
    },
  },
  mounted() {
    if (this.isContentScrollable) {
      const contents = document.querySelectorAll("[id$='-content']");
      for (const [index, element] of contents.entries()) {
        this.intervals[index].interval = setInterval(() => {
          if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            this.scrollDown = false;
          }
          if (element.scrollTop === 0 ) {
            this.scrollDown = true;
          }
          if (this.scrollDown) element.scrollBy(0, 1);
          else element.scrollBy(0, -1);
          this.intervals[index].previousScrollTop = element.scrollTop
        }, 50);
      }
    }
  },
  beforeDestory() {
    for (const interval of this.intervals) {
      if (interval) clearInterval(interval);
    }
  },
};
</script>

<style scoped>
.pma-label-images img {
  height: 2.2em;
}

.pma-label-images img.csc {
  height: 1.5em;
  margin-left: 0.2em;
  margin-bottom: 0.35em;
}

.pma-label-images,
.pma-label-text {
  margin-top: 0;
  margin-bottom: 0;
  height: 50%;
}

.pma-label-text {
  font-size: 0.73em;
  line-height: 0.9;
  padding-top: 0.45em;
  font-family: Arial, Helvetica, sans-serif;
}

.pma-label-text .currency-indicator {
  font-size: 0.6em;
}

.pma-label-box {
  color: rgb(255, 255, 255);
  font-size: 1em;
  font-weight: 200;
  padding-left: 0.6em;
  font-family: "Verdana";
}

.is-green-bg {
  background-color: rgb(36, 176, 9);
}

.is-green-border {
  border: solid 1px rgb(36, 176, 9);
}

.is-orange-bg {
  background-color: rgb(255, 138, 35);
}

.is-orange-border {
  border: solid 1px rgb(255, 138, 35);
}

.is-blue-bg {
  background-color: rgb(58, 140, 255);
}

.is-blue-border {
  border: solid 1px rgb(58, 140, 255);
}

.pma-sales-box .content {
  text-align: center;
  font-size: 0.75rem;
}

.sales-figures {
  margin-left: 1em;
  margin-right: 1em;
}

.multiple-currencies {
  background-color: #3f3f3f;
  color: #ffffff;
  padding: 1em;
  font-size: 0.8em;
  text-align: center;
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.data-list {
  margin-left: 0;
  margin-right: 0;
}

.topper {
  height: 4rem;
}

.is-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  max-height: 20rem;
  overflow-y: auto;
  padding-top: 0.5rem;
}

.content {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.content::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
</style>
