<template>
  <div class="hello">
    <Dashboard />
  </div>
  
</template>

<script>
import Dashboard from '../components/Dashboard.vue'

export default {
    name: 'ParametersKiosk',
    components: {
        Dashboard,
    },
    props: {
        
    },
    computed: {
        
    },
    data() {
        return {
        };
    },
    created() {
        this.checkParams();
    },
    mounted() {

    },
    methods: {
        checkParams()
        {
            let uri = window.location.search.substring(1);
            
            // misconfigured kiosk deployment will add ?computerId=... to the end of the URL, invalidating the last param, the '?' could be changed by the browser to %3F
            uri = uri.replace("?", "&"); 
            uri = uri.replace("%3F", "&");
            
            this.$store.dispatch('setUrlParams', uri);
            let params = new URLSearchParams(uri);
            let caseInsensitiveParams = {};
            params.forEach((value, param) => {
                caseInsensitiveParams[param.toLowerCase()] = value;
            });
            
            this.languageParam(caseInsensitiveParams['language']);
            this.checkForUrlKey(caseInsensitiveParams['key']);
            this.intervalSecondsParam(caseInsensitiveParams['interval_seconds']);
            this.enableGoals(caseInsensitiveParams['enable_goal'],
                caseInsensitiveParams['enable_goal_page1'],
                caseInsensitiveParams['enable_goal_page2'],
                caseInsensitiveParams['enable_goal_page3']);
            this.pageParams(caseInsensitiveParams);
        },
        languageParam(language)
        {
            if (typeof language === 'string')
            {
                let langChoice = language.toLowerCase();
                if (this.$i18n.availableLocales.includes(langChoice))
                {
                    this.$i18n.locale = langChoice;
                }
            }
        },
        checkForUrlKey(key)
        {
            if (typeof key === 'string')
            {
                this.$store.dispatch('setAuthKey', key);
            }
        },
        intervalSecondsParam(interval)
        {
            if (typeof interval === 'string')
            {
                let intervalSeconds = parseInt(interval)
                if ((intervalSeconds >= 5) && (intervalSeconds <= 600))
                {
                    this.$store.dispatch('setIntervalSeconds', intervalSeconds);
                }
            }
        },
        enableGoals(enableGoalAll, enableGoalP1, enableGoalP2, enableGoalP3)
        {
            if (enableGoalAll === 'true')
            {
                this.$store.dispatch('enableGoalValuesPage1');
                this.$store.dispatch('enableGoalValuesPage2');
                this.$store.dispatch('enableGoalValuesPage3');
                return
            }
            
            if (enableGoalP1 === 'true')
            {
                this.$store.dispatch('enableGoalValuesPage1');
            }

            if (enableGoalP2 === 'true')
            {
                this.$store.dispatch('enableGoalValuesPage2');
            }

            if (enableGoalP3 === 'true')
            {
                this.$store.dispatch('enableGoalValuesPage3');
            }
        },
        pageParams(params)
        {
            let page1Pmas = params['page1_pma_no'];
            let page1Country = params['page1_ru_code'];

            let page2Pmas = params['page2_pma_no'];
            let page2Country = params['page2_ru_code'];

            let page3Pmas = params['page3_pma_no'];
            let page3Country = params['page3_ru_code'];

            let setPages = {
                p1: null,
                p2: null,
                p3: null,
            };

            if (typeof page1Pmas === 'string')
            {
                // page 1 has PMA(s)
                // It may also have a country set, but we don't support showing both
                this.$store.dispatch('initPage1', {option: 'pmas', selection: page1Pmas});
                setPages.p1 = true;
            }
            else if (typeof page1Country === 'string')
            {
                // page 1 has country
                this.$store.dispatch('initPage1', {option: 'country', selection: page1Country});
                setPages.p1 = true;
            }
            else 
            {
                // no page 1
                this.$store.dispatch('initPage1', {option: null, selection: null});
                setPages.p1 = false;
            }

            if (typeof page2Pmas === 'string')
            {
                // page 2 has PMA(s)
                // It may also have a country set, but we don't support showing both
                this.$store.dispatch('initPage2', {option: 'pmas', selection: page2Pmas});
                setPages.p2 = true;
            }
            else if (typeof page2Country === 'string')
            {
                // page 2 has country
                this.$store.dispatch('initPage2', {option: 'country', selection: page2Country});
                setPages.p2 = true;
            }
            else 
            {
                // no page 2
                this.$store.dispatch('initPage2', {option: null, selection: null});
                setPages.p2 = false;
            }

            if (typeof page3Pmas === 'string')
            {
                // page 3 has PMA(s)
                // It may also have a country set, but we don't support showing both
                this.$store.dispatch('initPage3', {option: 'pmas', selection: page3Pmas});
                setPages.p3 = true;
            }
            else if (typeof page3Country === 'string')
            {
                // page 3 has country
                this.$store.dispatch('initPage3', {option: 'country', selection: page3Country});
                setPages.p3 = true;
            }
            else 
            {
                // no page 3
                this.$store.dispatch('initPage3', {option: null, selection: null});
                setPages.p3 = false;
            }

            if(setPages.p1 && !setPages.p3)
            {
                this.$store.commit('page3TeaserEligible');
            }

            this.$store.dispatch('setEnabledPages', setPages);

            this.$store.dispatch('initPageState');
        },
        pmaInUrlCheck()
        {
            let urlParts = window.location.pathname.split('/');
            if (urlParts.length > 1)
            {
                this.$store.dispatch('pmaSetInUrl', urlParts[1]);
            }
        },

    },
    watch: {

    },
}
</script>

<style scoped>

</style>
