<template>
  <div class="hello">
    <Dashboard />
  </div>
  
</template>

<script>
import axios from 'axios'

import Dashboard from '../components/Dashboard.vue'
import WarningBlock from '../components/WarningBlock.vue';

export default {
    name: 'ConfiguredKiosk',
    components: {
        Dashboard,
        WarningBlock
    },
    props: {
        
    },
    computed: {
        kioskConfigCode() {
            return this.$route.params.kioskConfigCode.split('&')[0];
        }
    },
    data() {
        return {
            kioskConfiguration: {}
        };
    },
    async mounted() {
        const date = new Date();
        const storage_test = localStorage.getItem('storage_test') || 'Dates Initialised:';
        const split_storage = storage_test.split(' ');
        let new_storage_item;
        if (split_storage.length > 3) {
            new_storage_item = `Dates Initialised: ${split_storage[split_storage.length - 1]} ${date.toISOString()}`
        } else {
            new_storage_item = `${storage_test} ${date.toISOString()}`;
        }
        localStorage.setItem('storage_test', new_storage_item); 

        this.getConfiguration(new_storage_item);
    },
    methods: {
        getConfiguration(new_storage_item)
        {
            let context = this;
            axios.get(process.env.VUE_APP_API_URL + '/configuredKiosk/config', {
                params: {
                    configCode: context.kioskConfigCode,
                    new_storage_item
                }
            }).then(function (response) {
                context.$store.dispatch('setKioskConfigCode', context.kioskConfigCode);
                context.$store.commit('setKioskConfiguration', response.data);
                if (response.data.page_order) context.$store.commit('setConfiguredOrdering', response.data.page_order);
                context.kioskConfiguration = response.data;
                context.setOptions()
            })
            .catch(error => {
                console.log(error);
                if (error.response.data.error === 'Forbidden ip') {
                    context.$store.commit('ipRestricted');
                }
                console.log(error.code);
            });
        },
        setOptions()
        {
            let langChoice = this.kioskConfiguration.language;
            if (this.$i18n.availableLocales.includes(langChoice))
            {
                this.$i18n.locale = langChoice;
            }

            this.$store.dispatch('setIntervalSeconds', this.kioskConfiguration.interval);
            
            if (this.kioskConfiguration.page1_show_goals)
                this.$store.dispatch('enableGoalValuesPage1');

            if (this.kioskConfiguration.page2_show_goals)    
                this.$store.dispatch('enableGoalValuesPage2');

            if (this.kioskConfiguration.page3_show_goals)    
                this.$store.dispatch('enableGoalValuesPage3');
            
            if (this.kioskConfiguration.show_ikea_logo)    
                this.$store.commit('enableIkeaLogo');

            let setPages = {
                p1: null,
                p2: null,
                p3: null,
                p4: null,
            };
        
            if ((this.kioskConfiguration.page1_ru_code) || (this.kioskConfiguration.page1_pma_no))
            {
                if (this.kioskConfiguration.page1_ru_code)
                {
                    this.$store.dispatch('initPage1', {option: 'country', selection: this.kioskConfiguration.page1_ru_code});
                }
                else
                {
                    this.$store.dispatch('initPage1', {option: 'pmas', selection: this.kioskConfiguration.page1_pma_no});
                }
                setPages.p1 = true;
            }

            if ((this.kioskConfiguration.page2_ru_code) || (this.kioskConfiguration.page2_pma_no))
            {
                if (this.kioskConfiguration.page2_ru_code)
                {
                    this.$store.dispatch('initPage2', {option: 'country', selection: this.kioskConfiguration.page2_ru_code});
                }
                else
                {
                    this.$store.dispatch('initPage2', {option: 'pmas', selection: this.kioskConfiguration.page2_pma_no});
                }
                setPages.p2 = true;
            }

            if ((this.kioskConfiguration.page3_ru_code) || (this.kioskConfiguration.page3_pma_no))
            {
                if (this.kioskConfiguration.page3_ru_code)
                {
                    this.$store.dispatch('initPage3', {option: 'country', selection: this.kioskConfiguration.page3_ru_code});
                }
                else
                {
                    this.$store.dispatch('initPage3', {option: 'pmas', selection: this.kioskConfiguration.page3_pma_no});
                }
                setPages.p3 = true;
            }
        
            if (this.kioskConfiguration.page4_pma_no)
            {
                this.$store.dispatch('setPage4Filters', {
                    filterIncludeHfo: this.kioskConfiguration.page4_filter_hfo,
                    filterIncludeFood: this.kioskConfiguration.page4_filter_food,
                    filterIncludeServices: this.kioskConfiguration.page4_filter_services,
                    filterCompleteSleep: this.kioskConfiguration.page4_complete_sleep,
                });
                this.$store.dispatch('initPage4', {option: 'pmas', selection: this.kioskConfiguration.page4_pma_no});
                
                setPages.p4 = true;
            }

            if (this.kioskConfiguration.page5_selection)
            {
                this.$store.dispatch('initPage5', { selection: this.kioskConfiguration.page5_selection });
                setPages.p5 = true;
            }

            this.$store.dispatch('setEnabledPages', setPages);

            this.$store.dispatch('initPageState');
        },
        
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
