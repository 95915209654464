<template>
  <div class="hello">
    <div id="hourlyBreakdownSalesGraph" style="height: 200px; width: 1000px;"></div>
  </div>
  
</template>

<script>

var echarts = require('echarts');
var formatNumberGenerator = require('../FormatNumberGenerator');

var eChartBreakdown;

export default {
    name: 'HourlyBreakdownSalesGraph',
    props: {
        msg: String
    },
    computed: {
        dailyData() {
            return this.$store.state.page3data.dailyData;
        },
        appContainerWidth() {
            return this.$store.state.appContainerWidth;
        },
    },
    data() {
        let formatNumber = this.formatNumber
        return {
            chartOptions: {
                tooltip: {
                    trigger: 'axis'
                },
                grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '0%',
                    top: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    axisTick: {
                        show: false,
                    },
                    data: [],
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: function (value, index)
                        {
                            return formatNumber(value)
                        },
                    },
                },
                legend: {
                    show: false,
                },
                series: [
                    {
                        data: [],
                        type: 'line',
                        smooth: true,
                        name: 'Sale Today',
                        lineStyle: {
                            width: 5,
                        },
                    },
                    {
                        data: [],
                        type: 'bar',
                        name: 'Historical Average Sale',
                        barGap: '0%',
                        barWidth: 10,
                        itemStyle: {
                            color: 'rgba(214, 214, 214, 1)',
                        },
                    },
                ]
            },
        };
    },
    mounted() {
        eChartBreakdown = echarts.init(document.getElementById('hourlyBreakdownSalesGraph'));
        if (this.$store.state.page3data.nextReloadTime !== null)
        {
            this.convertData();
        }
    },
    methods: {
        convertData()
        {
            this.chartOptions.xAxis.data = [];
            this.chartOptions.series[0].data = [];
            this.chartOptions.series[1].data = [];

            this.dailyData.forEach((halfHour) => {
                this.chartOptions.xAxis.data.push(halfHour.tm)
                this.chartOptions.series[0].data.push(halfHour.sale_delta)
                this.chartOptions.series[1].data.push(halfHour.sale_delta_hist)
            })
            eChartBreakdown.setOption(this.chartOptions, {notMerge: true});
        },
        formatNumber(input)
        {
            let formatter = formatNumberGenerator({
                decimal_place: this.$t('html_formatting.decimal_place'),
                thousands_seperator:  this.$t('html_formatting.thousands_seperator')
            })

            return formatter(input)
        },
    },
    watch: {
        dailyData: {
            handler(newValue) {
                this.convertData();
            },
            deep: true,
        },
        appContainerWidth(newAppWidth) {
            const container = document.getElementById('hourlyBreakdownSalesGraph')

            const chartWidthFactor = 0.70;
            const chartHeightFactor = 0.11;
            
            const newChartWidth = Math.floor(newAppWidth * chartWidthFactor);
            const newChartHeight = Math.floor(newAppWidth * chartHeightFactor);

            container.style.width = (newChartWidth)+"px";
            container.style.height = (newChartHeight)+"px";

            eChartBreakdown.resize({width: newChartWidth, height: newChartHeight});
        },
    },
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.hello {
    margin-top: 0.1rem;
}

</style>
