import Vue from 'vue'
import VueRouter from 'vue-router'
import ParametersKiosk from '../views/ParametersKiosk.vue'
import ConfiguredKiosk from '../views/ConfiguredKiosk.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'ParametersKiosk',
    component: ParametersKiosk
  },
  {
    path: '/kiosk/:kioskConfigCode',
    name: 'ConfiguredKiosk',
    component: ConfiguredKiosk
  },
]


const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
