<template>
    <div>
        <div id="main" style="height: 600px; width: 600px;"></div>
    </div>
</template>

<script>
const echarts = require('echarts');
const formatNumberGenerator = require('../FormatNumberGenerator');
const salesAndGoalLegendTranslation = require('../SalesAndGoalLegendTranslation');
let myChart;
export default {
    name: 'SimpleGraph',
    props: {
        yAxis: Array,
        xAxis: Array
    },
    data() {
        const translations = {sales: this.$t('chart_keys.sales'), goal: this.$t('chart_keys.goal')};
        const salesAndGoalLegendFormatter = salesAndGoalLegendTranslation(translations);
        const formatNumber = this.formatNumber;
        return {
            options: {
                legend: {
                    formatter: salesAndGoalLegendFormatter,
                    left: '10%',
                },
                title: {},
                textStyle: {
                    fontSize: '',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                distance: {
                    min: 0,
                    max: 100
                },
                grid: {
                    left: '4%',
                    right: '9%',
                    bottom: '3%',
                    containLabel: true
                },
                yAxis: {
                    type: 'category',
                    axisLabel: {
                        fontSize: '',
                        interval: 0,
                        overflow: 'truncate'
                    },
                    data: this.yAxis
                },
                xAxis: {
                    type: 'value',
                    splitLine: {
                        lineStyle: {
                            width: 0.4,
                            type: 'dotted'
                        },
                    },
                    axisLabel: {
                        formatter: function (value, index)
                        {
                            return formatNumber(value)
                        },
                        fontSize: ''
                    },
                },
                series: [
                    {
                        type: 'bar',
                        itemStyle: {
                            color: 'rgba(30, 161, 90, 1)',
                        },
                        barWidth: '',
                        label: {
                            show: true,
                            formatter: this.salesBarTitleCallback,
                            color: 'rgba(0, 0, 0, 1)',
                            position: 'right',
                            distance: 7,
                            padding: [0, 0, 0, 0],
                            fontWeight: '',
                            fontSize: '',
                            verticalAlign: ''
                        },
                        data: this.xAxis
                    },

                ]
            },
        }
    },
    computed: {
        appContainerWidth() {
            return this.$store.state.appContainerWidth;
        }
    },
    methods: {
        formatNumber(input) {
            const formatter = formatNumberGenerator({
                decimal_place: this.$t('html_formatting.decimal_place'),
                thousands_seperator:  this.$t('html_formatting.thousands_seperator')
            })
            return formatter(input)
        },
        convertThousandsSeparator(input){
            return input.replaceAll(',', this.$t('html_formatting.thousands_seperator'))
        },
        salesBarTitleCallback(object) {
            return this.convertThousandsSeparator(typeof object.data === 'object' ? object.data.value.toLocaleString() : object.data.toLocaleString());
        },    
        setChartFontSize(setOption=true) {
            const newAppWidth = this.appContainerWidth;
            const numItemsInChart = this.options.series[0].data.length;
            const itemFontSize = Math.round(newAppWidth / Math.max((3.85 * numItemsInChart), 155));

            this.options.textStyle.fontSize = Math.round(newAppWidth / 170);

            this.options.yAxis.axisLabel.fontSize = itemFontSize;
            this.options.series[0].label.fontSize = itemFontSize;
            
            if (setOption) myChart.setOption(this.options, true);
        },

    },
    mounted() {
        const container = document.getElementById('main');
        this.setChartFontSize(false);
        myChart = echarts.init(container);
        myChart.setOption(this.options);

        const newAppWidth = this.appContainerWidth;

        const chartWidthFactor = 0.56;
        const chartHeightFactor = 0.45;
        
        const newChartWidth = Math.floor(newAppWidth * chartWidthFactor);
        const newChartHeight = Math.floor(newAppWidth * chartHeightFactor);

        container.style.width = (newChartWidth)+"px";
        container.style.height = (newChartHeight)+"px";

        myChart.resize({ width: newChartWidth, height: newChartHeight });
        this.setChartFontSize();
        myChart.setOption(this.options, true);
},
    watch: {
        appContainerWidth(newAppWidth) {
            const container = document.getElementById('main')

            const chartWidthFactor = 0.56;
            const chartHeightFactor = 0.45;
            
            const newChartWidth = Math.floor(newAppWidth * chartWidthFactor);
            const newChartHeight = Math.floor(newAppWidth * chartHeightFactor);

            container.style.width = (newChartWidth)+"px";
            container.style.height = (newChartHeight)+"px";

            myChart.resize({ width: newChartWidth, height: newChartHeight });
            this.setChartFontSize();
            myChart.setOption(this.options, true);
        },

    }
}
</script>

<style>

</style>