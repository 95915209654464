<template>
  <div class="hourlyForecastDetails">  
    <div class="stack">
    <span class="description">{{ $t('data_info.page_selection') }}<br></span>
    <div id="selectionTextContainer">
    <div class="selectionText" v-for="selection in selectionNames" v-bind:key="selection">
        {{ selection }}
    </div>
    </div>

    </div>

    <div class="stack">
    <div id="dataCalcTime" class="sale-info">
        <span class="description">{{ $t('hourly_page.data_calc_time') }}<br></span>
        {{ dataCalculationTime }}
        </div>
    </div>

    <div class="stack">
        <div id="currentSalesBox" class="sale-info">
        <span class="description">{{ $t('hourly_page.current_sale') }}<br></span>
        {{ currentSale }}
        </div>
    </div>

    <div class="stack">
        <div id="saleForecastBox" class="sale-info">
        <span class="description">{{ $t('hourly_page.estm_sale_eod') }}<br></span>
        {{ saleForecast }}
        </div>
    </div>

    <div class="stack" v-if="goalValuesEnabled">
        <div id="goalBox" class="sale-info">
        <span class="description">{{ $t('hourly_page.goal_eod') }}<br></span>
        {{ goal }}
        </div>
    </div>
    
  </div>
  
</template>

<script>
var formatNumberGenerator = require('../FormatNumberGenerator');

export default {
    name: 'CurrentSalesScoreboard',
    props: {
        msg: String
    },
    computed: {
        dailyData() {
            return this.$store.state.page3data.dailyData;
        },
        currentSale() {

            if (this.currentData === null)
            {
                return 'No Sales Yet'
            }
            else
            {
                return this.formatNumber(this.currentData.sale);
            }
        },
        saleForecast() {
            return this.formatNumber(this.endOfDay.sale_forecast);
        },
        goal() {
            return this.formatNumber(this.endOfDay.goal_val);
        },
        dataCalculationTime() {
            if (this.currentData === null)
            {
                return 'No Sales Yet'
            }
            else
            {
                return this.currentData.tm
            }          
        },
        currentData() {
            let x = null
            this.dailyData.forEach((halfHour) => {
                if (halfHour.data_range == 'CURRENT')
                {
                    x = halfHour
                }
            })
            return x;
        },
        endOfDay() {
            return this.dailyData.slice(-1)[0] || {}
        },
        selectionNames() {
            return this.$store.state.page3data.selectionNames;
        },
        goalValuesEnabled()
        {
            return this.$store.state.goalValuesEnabledPage3;
        },
    },
    data() {
        return {          
        };
    },
    mounted() {
        this.calculateSelectionTextFontSize(this.selectionNames.length);
    },
    methods: {
        setSelectionTextFontSize(fontSize)
        {
            document.getElementById('selectionTextContainer').style.fontSize = fontSize
        },
        calculateSelectionTextFontSize(numberOfSelections)
        {
            switch (true) {
                case (numberOfSelections < 2):
                    this.setSelectionTextFontSize('1em')
                    break;
                case (numberOfSelections < 4):
                    this.setSelectionTextFontSize('0.7em')
                    break;
                default:
                    this.setSelectionTextFontSize('0.5em')
                    break;
            }
        },
        formatNumber(input)
        {
            let formatter = formatNumberGenerator({
                decimal_place: this.$t('html_formatting.decimal_place'),
                thousands_seperator:  this.$t('html_formatting.thousands_seperator')
            })

            return formatter(input)
        },
    },
    watch: {
        selectionNames(selections) {
            this.calculateSelectionTextFontSize(selections.length);
        }
    },
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.hourlyForecastDetails {
    text-align: center;
    font-size: 1.2em;

    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-top: 0em;
    padding-bottom: 1.5em;
}

.description {
    font-size: 0.6rem;
}

.sale-info {
    border: #a5a5a5 solid 0.105em;
    border-radius: 0.7em;
    padding-bottom: 0.3em;
}

.stack {
    margin-bottom: 1em;
}

#currentSalesBox {
    border-color: rgba(204,0,0, 1);
}

#saleForecastBox {
    border-color: rgba(74,134,232, 1);
}

#goalBox {
    border-color: rgba(255,153,0, 1);
}

</style>