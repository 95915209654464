module.exports = function (translations) {
    return function (name) {
        switch (name) {
            case 'Sales':
                return translations.sales;
            case 'Goal':
                return translations.goal;
            default:
                return name;
        }
    }
}