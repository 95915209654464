const en = {
    language_name:          'English',
    dash_title: {
        page1:              'Realtime Sales Dashboard',
        page2:              'Realtime Sales Dashboard Benchmarking'
    },
    chart_section_titles: {
        hfbs:               'Range',
        pmas:               'Market',
        sales_channels:     'Sales Channel',
        benchmarking:       'Country Benchmarking'
    },
    html_formatting: {
        decimal_place: '.',
        thousands_seperator: ','
    },
    data_info: {
        auth_error:         'Authentication Error',
        latest_transaction: 'Latest Transaction',
        page_selection:     'Selection',
        last_refresh:       'Last Refresh',
        selection_extra:    'and $ more',
    },
    sales_channels: {
        total:              'Total',
        store:              'Store',
        online_csc:         'Online',
        online_csc_lbr:     'Online',
    },
    chart_keys: {
        sales:              'Created Gross Sales',
        goal:               'Created Sales Goal',
        total_sales:        'Total Sales',
        total_quantity:     'Total Quantity',
    },
    hfb_names: {
        '01': 'Living room seating',
        '02': 'Store and organise furniture',
        '03': 'Workspaces',
        '04': 'Bedroom furniture',
        '05': 'Beds & Mattresses',
        '06': 'Bathroom & Water',
        '07': 'Kitchen',
        '08': 'Dining',
        '09': 'Children´s IKEA',
        '10': 'Lighting and Home electronics',
        '11': 'Bed and bath textiles',
        '12': 'Home textiles',
        '13': 'Rugs',
        '14': 'Cooking',
        '15': 'Eating',
        '16': 'Decoration',
        '17': 'Outdoor & Secondary storage',
        '18': 'Home organisation',
        '20': 'Other business opportunities',
        '60': 'Food Services',
        '61': 'Consumer packaged food goods',
        '70': 'Home Appliances',
        '92': 'Family',
        '95': 'Administrative Area 95',
        '97': 'Services',
    },
    hourly_page: {
        hourly_sales: 'Hourly Sales',
        cumulative: 'Cumulative',
        hourly: 'Hourly',
        selection: 'Selection',
        data_calc_time: 'Data Calculation Time',
        current_sale: 'Current Sale',
        estm_sale_eod: 'Estimated Sale EOD',
        goal_eod: 'Goal EOD',
        hist_avg_sale: 'Historical Average Sale',
        sale_today: 'Sale Today',
        sale_estm: 'Sale Estimate',
        goal: 'Goal',
    },
    top_item_page: {
        top_items_by_store: 'Top Items By Store',
        range_offer: 'Range offer selection',
        top_item_by_sale: 'Top 10 items by sale value',
        top_item_by_qty: 'Top 10 items by sale quantity',
        rank: 'Rank',
        item_nr: 'Item Number',
        item_name: 'Item Name',
        total_qty: 'Total Qty',
        hfo: 'HFO',
        food: 'Food',
        services: 'Services',
    }
}

const fr = {
    language_name:          'Français',
    dash_title: {
        page1:              'Tableau de bord en temps réel des ventes',
        page2:              'Analyse comparative du tableau de bord des ventes en temps réel'
    },
    chart_section_titles: {
        hfbs:               'Gamme',
        pmas:               'Marché',
        sales_channels:     'Canal de vente',
        benchmarking:       'Analyse comparative des pays'
    },
    html_formatting: {
        decimal_place: ',',
        thousands_seperator: String.fromCharCode(160) // 160 = non-breaking space
    },
    data_info: {
        auth_error:         'Erreur d\'authentification',
        latest_transaction: 'Dernière transaction',
        page_selection:     'Sélection',
        last_refresh:       'Dernier rafraîchissement',
    },
    sales_channels: {
        total:              'Total',
        store:              'Boutique',
        online_csc:         'En ligne',
        online_csc_lbr:     'En ligne',
    },
    chart_keys: {
        sales:              'Ventes réalisées', // These two are changes also
        goal:               'Objectifs de ventes',
        total_sales:        'Ventes totales',
        total_quantity:     'Quantité totale',
    },
    hfb_names: {
        '01': 'Fauteuils et canapés de salon',
        '02': 'Rangement Salon & TV-Hifi',
        '03': 'Espaces de travail',
        '04': 'Meubles pour la chambre',
        '05': 'Lits et matelas',
        '06': 'Salle de bain',
        '07': 'Cuisine',
        '08': 'Salle à manger',
        '09': 'IKEA des Petits',
        '10': 'Éclairage et éclairage connecté',
        '11': 'Textiles de lit et linge de toilette',
        '12': 'Textiles pour la maison',
        '13': 'Tapis',
        '14': 'Cuisiner',
        '15': 'Manger',
        '16': 'Décoration',
        '17': 'Extérieur et rangement secondaire',
        '18': 'Organisation de la maison',
        '20': 'Autres opportunités commerciales',
        '60': 'Services de restauration',
        '61': 'Produits alimentaires emballés pour la grande consommation',
        '70': 'Électroménagers',
        '92': 'Family',
        '95': 'Secteur administratif 95',
        '97': 'Services',
    },
    top_item_page: {
        top_items_by_store: 'Meilleurs Articles Par Magasin',
        range_offer: 'Offre ameublement et décoration',
        top_item_by_sale: 'Top 10 Articles par prix',
        top_item_by_qty: 'Top 10 Articles par quantités vendu',
        rank: 'Rang',
        item_nr: 'Référence',
        item_name: 'Désignation',
        total_qty: 'Quantités totales',
        hfo: 'Service Aménagement Intérieur',
        food: 'Restauration',
        services: 'Services',
    }
}

const frca = {
    language_name:          'Français',
    dash_title: {
        page1:              'Ventes en temps réel',
        page2:              'Analyse comparative des ventes'
    },
    chart_section_titles: {
        hfbs:               'Assortiment',
        pmas:               'Marché',
        sales_channels:     'Canal de vente',
        benchmarking:       'Analyse comparative des pays'
    },
    html_formatting: {
        decimal_place: ',',
        thousands_seperator: String.fromCharCode(160) // 160 = non-breaking space
    },
    data_info: {
        auth_error:         'Erreur d\'authentification',
        latest_transaction: 'Dernière transaction',
        page_selection:     'Emplacement',
        last_refresh:       'Dernière mise à jour',
        selection_extra:    'et $ encore',
        delay_message:      'Le retard de la mise à jour des données des ventes est attribuable à notre source. Nous sommes désolés de cet inconvénient.',
    },
    sales_channels: {
        total:              'Total',
        store:              'Magasin',
        online_csc:         'En ligne',
        online_csc_lbr:     'En ligne',
    },
    chart_keys: {
        sales:              'Ventes',
        goal:               'Objectif',
        total_sales:        'Ventes totales',
        total_quantity:     'Quantité totale',
    },
    hfb_names: {
        '01': 'Sièges de salon',
        '02': 'Rangement et organisation de salon',
        '03': 'Espaces de travail',
        '04': 'Mobilier de chambre à coucher',
        '05': 'Lits et matelas',
        '06': 'Salle de bains',
        '07': 'Cuisine',
        '08': 'Salle à manger',
        '09': 'IKEA des petits',
        '10': 'Éclairage et appareils électroniques',
        '11': 'Textiles pour chambre et salle de bains',
        '12': 'Textiles pour la maison',
        '13': 'Tapis',
        '14': 'Articles de cuisines',
        '15': 'Ustensiles et vaisselles',
        '16': 'Décoration',
        '17': 'Rangement extérieur et secondaire',
        '18': 'Organisation de la maison',
        '20': 'Autres occasions commerciales',
        '60': 'Services de restauration',
        '61': 'Produits alimentaires emballés pour la grande consommation',
        '70': 'Électroménagers',
        '92': 'Articles pour la famille',
        '95': 'Secteur administratif 95',
        '97': 'Services',
    },
    hourly_page: {
        hourly_sales:   'Ventes Horaires', /// This is changes and two others as well
        cumulative:     'Total cumulatif',
        hourly:         'Ventes Par Heure',
        selection:      'Emplacement',
        data_calc_time: 'Heure du calcul',
        current_sale:   'Ventes actuelles',
        estm_sale_eod:  'Estimation des ventes en fin de journée',
        goal_eod:       'Objectif de fin de journée',
        hist_avg_sale:  'Ventes moyennes antérieures',
        sale_today:     'Ventes du jour',
        sale_estm:      'Estimation des ventes',
        goal:           'Objectif',
    },
    top_item_page: {
        top_items_by_store: 'Meilleurs Articles Par Magasin',
        range_offer: 'Sélection de l´offre de gamme',
        top_item_by_sale: 'Top 10 des articles par valeur de vente',
        top_item_by_qty: 'Top 10 des articles par quantité de vente',
        rank: 'Rang',
        item_nr: 'Numéro d´article',
        item_name: 'Nom de l´article',
        total_qty: 'Qté totale',
        hfo: 'Service Aménagement Intérieur',
        food: 'Aliments',
        services: 'Prestations de service',
    }
}

const de = {
    language_name:          'Deutsch',
    dash_title: {
        page1:              'Realtime Sales Dashboard',
        page2:              'Realtime Sales Dashboard Benchmarking'
    },
    chart_section_titles: {
        hfbs:               'Sortiment',
        pmas:               'Markt',
        sales_channels:     'Sales Channel',
        benchmarking:       'Länder-Benchmarking'
    },
    html_formatting: {
        decimal_place: ',',
        thousands_seperator: String.fromCharCode(160) // 160 = non-breaking space
    },
    data_info: {
        auth_error:         'Authentifizierungsfehler',
        latest_transaction: 'Letzte Transaktion',
        page_selection:     'Auswahl',
        last_refresh:       'Letzte Aktualisierung',
        selection_extra:    'und $ mehr',
        delay_message:      'Online Umsätze sind aufgrund unserer Quellen verzögert. Entschuldigung für die Umstände.',
    },
    sales_channels: {
        total:              'Total',
        store:              'Store',
        online_csc:         'Online',
        online_csc_lbr:     'Online',
    },
    chart_keys: {
        sales:              'Umsatz',
        goal:               'Ziel',
        total_sales:        'Umsatz Total',
        total_quantity:     'Stückzahl Total',
    },
    hfb_names: {
        '01': 'Wohnzimmer-Sitzmöbel',
        '02': 'Wohnzimmer-Aufbewahrung',
        '03': 'Arbeitsplätze',
        '04': 'Schlafzimmermöbel',
        '05': 'Betten und Matratzen',
        '06': 'Badezimmer',
        '07': 'Küche',
        '08': 'Essplätze',
        '09': 'Kinderwelt',
        '10': 'Beleuchtung',
        '11': 'Bett- und Badtextilien',
        '12': 'Heimtextilien',
        '13': 'Teppiche',
        '14': 'Kochen',
        '15': 'Essen',
        '16': 'Dekoration',
        '17': 'Outdoor',
        '18': 'Verstauen und Ordnen',
        '20': 'Andere Geschäftsmöglichkeiten',
        '60': 'Lebensmitteldienstleistungen',
        '61': 'Verpackte Lebensmittel für den Endverbraucher',
        '70': 'Elektrogeräte',
        '92': 'Family',
        '95': 'Administrative Area 95',
        '97': 'Services',
    },
    hourly_page: {
        hourly_sales:   'Stundenumsatz',
        cumulative:     'Kumuliert',
        hourly:         'Stündlich',
        selection:      'Auswahl',
        data_calc_time: 'Zeitpunkt der Berechnung',
        current_sale:   'Aktueller Umsatz',
        estm_sale_eod:  'Voraussichtlicher Umsatz am Tagesende',
        goal_eod:       'Tagesziel',
        hist_avg_sale:  'Historischer Umsatz',
        sale_today:     'Umsatz heute',
        sale_estm:      'Voraussichtlicher Umsatz',
        goal:           'Ziel',
    },
    top_item_page: {
        top_items_by_store: 'Top Artikel Store',
        range_offer: 'Auswahl',
        top_item_by_sale: 'Top 10 Artikel nach Umsatz',
        top_item_by_qty: 'Top 10 Artikel nach Stückzahl',
        rank: 'Rang',
        item_nr: 'Artikelnummer',
        item_name: 'Artikelname',
        total_qty: 'Stückzahl Total',
        hfo: 'HFO',
        food: 'Food',
        services: 'Services',
    }
}


module.exports = { en, fr, frca, de };