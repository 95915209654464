<template>
    <div id="BySalesValueGraph"></div>
</template>

<script>

var echarts = require('echarts');
var formatNumberGenerator = require('../FormatNumberGenerator');
var saleValueChart;

export default {
    name: 'BySalesValueGraph',
    props: {
    },
    methods: {
        convertData() {
            this.chartOptions.yAxis.data = [];
            this.chartOptions.series[0].data = [];

            for (let i = 0; i < 10 - this.topItemsByValue.length; i++) {
                this.chartOptions.yAxis.data.push('-');
                this.chartOptions.series[0].data.push(null);
            }
            
            this.topItemsByValue.slice().reverse().forEach(topItem => {
                this.chartOptions.yAxis.data.push(topItem.rank);
                this.chartOptions.series[0].data.push(topItem.total_sale_gross);
            });

            saleValueChart.setOption(this.chartOptions)
        },
        formatNumber(input)
        {
            let formatter = formatNumberGenerator({
                decimal_place: this.$t('html_formatting.decimal_place'),
                thousands_seperator:  this.$t('html_formatting.thousands_seperator')
            })

            return formatter(input)
        },
    },
    data() {     
        let formatNumber = this.formatNumber;

        return {
            chartOptions: {
                legend: {
                    data: ['Total Sales'],
                    padding:[10,0,0,0],
                    textStyle: {
                        fontWeight: 900
                    }
                },
                grid: {
                    left: '4%',
                    right: '9%',
                    bottom: '3%',
                    top: '10%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: function (value, index)
                        {
                            return formatNumber(value)
                        },
                    },             
                },
                yAxis: {
                    type: 'category',
                    data: [],
                    axisLabel: {
                        interval: 0,
                        rich: {
                            itemName: {
                                fontWeight: 'bold',
                            },
                            hfb: {
                                align: 'left',
                            }
                        }
                    }
                },
                series: [
                    {
                        name: 'Total Sales',
                        type: 'bar',
                        position: 'bottom',
                        itemStyle: {
                        color: 'rgba(30, 161, 90, 1)',
                        },
                        stack: 'total',
                        label: {
                            show: true,
                            padding: [0, 0, 0, 0],
                            color: 'rgba(0, 0, 0, 1)',
                            position: 'right',
                            fontWeight: '',
                            fontSize: '',
                            verticalAlign: ''
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: []
                    },
                ]
            }
        }
    },
    computed: {
      topItemsByValue() {
          return this.$store.state.page4data.topItemsByValue;
      }, 
      appContainerWidth() {
            return this.$store.state.appContainerWidth;
        },
    },
    mounted() {
        saleValueChart = echarts.init(document.getElementById('BySalesValueGraph'));
        saleValueChart.setOption(this.chartOptions);

        if (this.topItemsByValue.length > 0)
        {
            this.convertData();
        }

        saleValueChart.setOption(this.chartOptions, {notMerge: true});
    },
    watch: {
        topItemsByValue: {
            handler() {
                this.convertData();
            },
            deep: true,
        },
        appContainerWidth(newAppWidth) { 
            const container = document.getElementById('BySalesValueGraph')

            const chartWidthFactor = 0.40;
            const chartHeightFactor = 0.21;
            
            const newChartWidth = Math.floor(newAppWidth * chartWidthFactor);
            const newChartHeight = Math.floor(newAppWidth * chartHeightFactor);

            container.style.width = (newChartWidth)+"px";
            container.style.height = (newChartHeight)+"px";

            saleValueChart.resize({width: newChartWidth, height: newChartHeight});
        },
    },
}

</script>

<style scoped>

</style>